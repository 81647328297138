import { AvailabilityResponse } from '@bookingkit-private/api-v4';
import { Availability } from '@/services/InventoryService/types';
import { InventoryServiceError } from '@/services/InventoryService/Errors/InventoryServiceError';
import { isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const getLocalStartDate = (localDate: string): Date => {
    const startDate = new Date(localDate);
    const eventTimeZone = localDate.slice(-6);
    const zonedDate = utcToZonedTime(startDate, eventTimeZone);
    return zonedDate;
};

export const fromApiModel = (fromApiModelInput: AvailabilityResponse):Availability => {
    const {
        id, experience_id, available, start_time_utc, option_id, total_capacity, labels, start_time_local,
    } = fromApiModelInput;
    if (id === undefined) {
        throw InventoryServiceError.fromInvalidProperty('id', 'string', id);
    }
    if (experience_id === undefined) {
        throw InventoryServiceError.fromInvalidProperty('experience_id', 'string', experience_id);
    }
    if (start_time_utc === undefined) {
        throw InventoryServiceError.fromInvalidProperty('start_time_utc', 'valid date', start_time_utc);
    }
    const date = new Date(start_time_utc);
    if (!isValid(date)) {
        throw InventoryServiceError.fromInvalidDateString(start_time_utc);
    }
    if (start_time_local === undefined) {
        throw InventoryServiceError.fromInvalidProperty('start_time_local', 'string', start_time_local);
    }
    if (!isValid(new Date(start_time_local))) {
        throw InventoryServiceError.fromInvalidDateString(start_time_local);
    }
    if (option_id === undefined) {
        throw InventoryServiceError.fromInvalidProperty('option_id', 'string', option_id);
    }
    if (available === undefined || typeof available !== 'number') {
        throw InventoryServiceError.fromInvalidProperty('available', 'number', available);
    }
    if (total_capacity === undefined || typeof total_capacity !== 'number') {
        throw InventoryServiceError.fromInvalidProperty('total_capacity', 'number', total_capacity);
    }

    if (labels === undefined || !Array.isArray(labels)) {
        throw InventoryServiceError.fromInvalidProperty('labels', 'String array', labels);
    }
    return {
        id,
        availableSlots: available,
        startUTCDate: new Date(start_time_utc),
        startLocalDate: getLocalStartDate(start_time_local),
        totalSlots: total_capacity,
        experienceId: experience_id,
        optionId: option_id,
        labels,
    };
};

export default { fromApiModel };
