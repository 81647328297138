import useApplicationState from '@/state/application';

let toastDisplayTimeOut: NodeJS.Timeout;

const displayShortcutToastMessage = () => {
    const {
        activateShortcutInfoToast, activateShortcutActionToast, isToastShortcutMessageAlreadyShown,
    } = useApplicationState();
    if (isToastShortcutMessageAlreadyShown()) {
        return;
    }
    toastDisplayTimeOut = setTimeout(() => {
        activateShortcutInfoToast({
            active: true,
            message: navigator.userAgent.includes('Mac') ? 'powerShortcuts.searchShortcutToastInfoMac' : 'powerShortcuts.searchShortcutToastInfoWindows',
        });
        activateShortcutActionToast();
    }, 1000);
};

const callback = (event: Pick<KeyboardEvent, 'preventDefault'>) => {
    const {
        isSearchModalActive, activateSearchModal, closeModal,
        isPowerShortcutsEnabled,
    } = useApplicationState();
    if (!isPowerShortcutsEnabled()) {
        return;
    }
    if (!isSearchModalActive()) {
        closeModal();
        activateSearchModal();
        displayShortcutToastMessage();
        event.preventDefault();
    } else {
        if (toastDisplayTimeOut) {
            clearTimeout(toastDisplayTimeOut);
        }
        closeModal();
    }
};

const windows = {
    callback,
    keys: ['control', 'f'],
};

const mac = {
    callback,
    keys: ['f', 'meta'],
};

export default {
    windows, mac,
};
