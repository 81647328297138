<template>
  <div :class="{'side-padding':!isSearch}" class="vendor-switch-loading-skeleton" aria-hidden="true">
    <div class="skeleton-wrapper-long">
      <skeleton-loader class="vendor-loading-full"/>
    </div>
    <div class="skeleton-wrapper-medium">
      <skeleton-loader class="vendor-loading-full"/>
    </div>
    <div class="skeleton-wrapper-short">
      <skeleton-loader class="vendor-loading-full"/>
    </div>
  </div>

</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { SkeletonLoader } from '@bookingkit/coppi';

export default {
    name: 'SupplierSwitchLoading.vue',
    components: {
        SkeletonLoader,
    },
    props: {
        isSearch: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.side-padding {
  padding: 0 var(--s6);
}

.vendor-switch-loading-skeleton {
  margin-top: var(--s11);
}

.skeleton-wrapper-long,
.skeleton-wrapper-medium,
.skeleton-wrapper-short {
  overflow: hidden;
  border-radius: var(--s3);
  margin-bottom: var(--s6);
}

.skeleton-wrapper-long {
  width: 66.666%;
}

.skeleton-wrapper-medium {
  width: 80%;
}

.skeleton-wrapper-short {
  width: 45%;
}

.vendor-loading-full {
  width: 500px;
}

</style>
