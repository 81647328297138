import { ListSupplierAppAggregateResponse } from '@bookingkit-private/api-v4/models/list-supplier-app-aggregate-response';
import { BkAppType } from '@/services/AppsService/types';
import { AppServiceError } from '@/services/AppsService/Errors/AppServiceError';

export const fromApiApp = (app: ListSupplierAppAggregateResponse): BkAppType => {
    const {
        id, active, title, description,
    } = app;
    if (id === undefined || active === undefined || title === undefined) {
        throw AppServiceError.fromMalformedResponse('id|active|title');
    }
    return {
        id,
        isActive: active,
        name: title,
        description,
    };
};

export default { fromApiApp };
