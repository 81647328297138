<template>
  <div :class="sellPageStyles">
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { BkNextRouteInterface } from '@/router/helpers/constants';
import { SELL_ROUTE_NAMES } from '../../constants';

const router = useRouter() as unknown as BkNextRouteInterface;

const sellPageStyles = computed(() => {
    if (router.path === SELL_ROUTE_NAMES.PAYMENTOPTIONS || router.path === SELL_ROUTE_NAMES.PAYMENTPROVIDERS) {
        return 'sell-page-route';
    }
    return '';
});

</script>

<style scoped>
.sell-page-route {
  display: grid;
  grid-gap: var(--s4);
  grid-template-columns: [full-start] 0 [main-start] repeat(4, 1fr) [main-end] 0 [full-end];
  width: 100%;
}

@media screen and (min-width: 769px) {
  .sell-page-route {
    grid-gap: var(--s6);
    grid-template-columns: [full-start] 0 [main-start] repeat(8, 1fr) [main-end] 0 [full-end];
  }
}

@media screen and (min-width: 1060px) {
  .sell-page-route {
    grid-template-columns: [full-start] minmax(calc(calc(100% - 1184px) / 2), 1fr) [main-start] repeat(12, 1fr) [main-end] minmax(calc(calc(100% - 1184px) / 2), 1fr) [full-end];
  }
}
</style>
