import router from '@/router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createGtm, VueGtmPlugin } from '@gtm-support/vue-gtm';
import { GTM_ID } from '@/bootstrap/environment';
import { SupplierType, UserType } from '@/models/User';

let gtmService: undefined | VueGtmPlugin;

export const useGtmService = () => {
    if (!gtmService) {
        gtmService = createGtm({
            id: GTM_ID,
            defer: false,
            compatibility: false,
            enabled: true,
            debug: false,
            loadScript: true,
            vueRouter: router,
            trackOnNextTick: false,
        });
    }
    return gtmService;
};

export const identifyDataLayerParams = (supplier:SupplierType, user:UserType) => {
    const datalayerParams = window.dataLayer || [];
    datalayerParams.push({
        event: 'onload',
        supplierId: supplier?.id,
        userId: user?.id,
    });
    window.dataLayer = datalayerParams;
};

export default { useGtmService, identifyDataLayerParams };
