export class ShortcutError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ShortcutError);
        }

        this.name = 'ShortcutError';
    }

    static forNotFoundShortcut(identifier:string): ShortcutError {
        return new ShortcutError(`Could not find shortcut for ${identifier}`);
    }

    static forShortcutAlreadyRegistered(identifier:string): ShortcutError {
        return new ShortcutError(`This shortcut is already registered, you can not override it. ${identifier}`);
    }
}

export default ShortcutError;
