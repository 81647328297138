export class ManticoreRouteNotFoundException extends Error {
    constructor(...params: any) {
        super(...params);
        this.message = `${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ManticoreRouteNotFoundException);
        }

        this.name = 'ManticoreRouteNotFoundException';
    }

    static fromNotFoundManticoreUrlInNamedRoute(name: string) {
        return new ManticoreRouteNotFoundException(`Could not find a manticore url for route with name: \`${name}\``);
    }

    static fromNamedRoute(name: string): ManticoreRouteNotFoundException {
        return new ManticoreRouteNotFoundException(`Could not find a route with name:  \`${name}\``);
    }
}

export default ManticoreRouteNotFoundException;
