import {
    SearchResult,
} from '@/components/SearchModal/SearchResultType';
import { CurrencyType } from '@/constants/currencySymbols';
import { COUPON } from '@/constants';
import { mainInfoHero } from '@/components/SearchModal/searchResultMapper';

export const fromApiCouponToSearchResultCoupon = (coupon :any) : SearchResult => ({
    scope: COUPON,
    name: coupon.title ?? '',
    code: coupon.code ?? '',
    id: coupon.id ?? '',
    orderDate: coupon.create_date ?? '',
    status: undefined,
    headBadges: [{ label: COUPON }],
    mainInfoHero: mainInfoHero({
        amount: coupon.amount_off?.value ?? 0,
        currency: coupon.amount_off?.currency as CurrencyType,
    },
    coupon.percentage_off),
});

export default { fromApiCouponToSearchResultCoupon };
