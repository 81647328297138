export class AppServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AppServiceError);
        }

        this.name = 'AppServiceError';
    }

    static fromMalformedResponse(missingProperty: string): AppServiceError {
        return new AppServiceError(`Response was missing ${missingProperty}`);
    }

    static fromMissingSupplier(): AppServiceError {
        return new AppServiceError('Supplier service was not able to return an identifier for this supplier during initialisation');
    }
}

export default AppServiceError;
