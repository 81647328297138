<template>
    <div class="error-screen">
      <h2>ERROR</h2>
    </div>
</template>
<script>
export default {
    name: 'Error',
};
</script>
<style scoped>
.error-screen {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
}

h2 {
  margin: auto;
}
</style>
