import { User as ApiUser } from '@bookingkit-private/api-v4';
import { UserServiceError } from '@/services/UserService/Errors';
import { UserType } from '@/models/User';
import { DEFAULT_LOCALE } from '@/bootstrap/environment';
import { ApplicationLocales } from '@/constants/locales';
import { UserLocaleEnum as ApiLocales } from '@bookingkit-private/api-v4/models/user';

const fromApiLocaleToApplicationLocale = (locale: ApiLocales): ApplicationLocale => {
    switch (locale) {
    case ApiLocales.CsCz:
        return ApplicationLocales.csCz;
    case ApiLocales.DaDk:
        return ApplicationLocales.daDk;
    case ApiLocales.DeDe:
        return ApplicationLocales.deDe;
    case ApiLocales.EnUs:
        return ApplicationLocales.enXY;
    case ApiLocales.EsEs:
        return ApplicationLocales.esEs;
    case ApiLocales.FiFi:
        return ApplicationLocales.fiFi;
    case ApiLocales.FrFr:
        return ApplicationLocales.frFr;
    case ApiLocales.ItIt:
        return ApplicationLocales.itIt;
    case ApiLocales.NlNl:
        return ApplicationLocales.nlNl;
    case ApiLocales.NnNo:
        return ApplicationLocales.noNo;
    case ApiLocales.SvSe:
        return ApplicationLocales.svSe;
    default:
        return ApplicationLocales.enXY;
    }
};

export const fromApiUserToUserTypeReducer = (user: ApiUser): UserType => {
    const validators = {
        id: (id: unknown) => typeof id === 'string',
        full_name: (name: unknown) => typeof name === 'string',
        email: (email: unknown) => typeof email === 'string',
        locale: (locale: unknown) => typeof locale === 'string',
    } as Record<keyof ApiUser, (args: unknown) => boolean>;

    Object.keys(validators).forEach((propertyToValidate) => {
        const userProperty = propertyToValidate as unknown as keyof ApiUser;
        if (!validators[userProperty](user[userProperty])) {
            throw UserServiceError.fromMissingProperty(propertyToValidate);
        }
    });

    return {
        id: user.id as UserType['id'],
        name: user.full_name as UserType['name'],
        email: user.email as UserType['email'],
        locale: fromApiLocaleToApplicationLocale(user.locale ?? DEFAULT_LOCALE),
    };
};

export default {
    fromApiUserToUserTypeReducer,
};
