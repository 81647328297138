export class OrderServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, OrderServiceError);
        }

        this.name = 'OrderServiceError';
    }

    static fromInvalidPropertyType(property: string, expectedType: string) {
        return new OrderServiceError(`Property \`${property}\` was not a valid \`${expectedType}\``);
    }

    static fromMissingProperty(property:string): OrderServiceError {
        return new OrderServiceError(`Property ${property} is missing in the data`);
    }

    static fromMissingRequestProperty(property: string, request: string): OrderServiceError {
        return new OrderServiceError(`Property ${property} must exists in the payload when using ${request}`);
    }

    static fromInvalidOrderStatus(received?: string): OrderServiceError {
        return new OrderServiceError(`Wrong order status received: \`${received}\`.`);
    }

    static fromMissingSupplier(): OrderServiceError {
        return new OrderServiceError('Supplier service was not able to return an identifier for this supplier during initialisation');
    }

    static fromInvalidOrderDate(property: string): OrderServiceError {
        return new OrderServiceError(`Order ${property} is not a valid date`);
    }

    static fromInvalidMoney(property: string): OrderServiceError {
        return new OrderServiceError(`Order ${property} is not a valid money`);
    }

    static fromInvalidCurrency(property: string): OrderServiceError {
        return new OrderServiceError(`Order ${property} is not a valid currency`);
    }
}

export default OrderServiceError;
