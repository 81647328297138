<template>
  <desktop v-if="showDesktopLayout" :key="applicationKey" :locked="locked" />
  <mobile v-if="showMobileLayout" :key="applicationKey" :locked="locked" />
  <modal
    v-if="getActiveModalView"
    :view="getActiveModalView"
    :anchorPoint="getModalAnchorPoint"
    :get-modal-properties="getModalProperties"
    :disableClosing="getModalDisableClosing" />
  <overlay
    v-if="modalIsOpen"
    v-bind="getModalConfiguration"
  />
  <toast-layer v-if="hasToasts" :toast-messages="toastQueue" @close-toast="removeToast" :is-mobile="showMobileLayout" />
  <splash-loader v-if="!hasLoadedKeyRequests && !mountingError" />
  <error v-if="mountingError" :error-code="mountingError" />
</template>
<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import { useSupplier } from '@/state/supplier';
import useApplicationState, { setIsDesktopLayout } from '@/state/application';
import useToasts from '@/components/Toasts/useToasts';
import useUser from '@/state/user';

import Modal from '@/components/Modal.vue';
import SplashLoader from '@/components/Loaders/SplashLoader.vue';
import ToastLayer from '@/components/Toasts/ToastLayer.vue';
import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { LOGGER_SERVICE, SUPPLIER_SERVICE } from '@/bootstrap/ServiceProviders';
import { SupplierServiceInterface } from '@/models/Services/SupplierServiceInterface';
import { DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH } from '@/bootstrap/environment';
import { bootIntercom } from '@/bootstrap/intercom';
import { VIEWPORT } from '@/constants/genericConstants';
import useTranslationService from '@/composables/useTranslationService';
import { SupplierType } from '@/models/User';
import { useUserSupplier } from '@/composables/useSupplier/useUserSupplier';

export default defineComponent({
    name: 'App',
    components: {
        ToastLayer,
        Desktop: defineAsyncComponent({
            loader: () => import('@/layouts/Desktop.vue'),
            loadingComponent: SplashLoader,
        }),
        Mobile: defineAsyncComponent(() => import('@/layouts/Mobile.vue')),
        Error: defineAsyncComponent(() => import('@/components/Errors/ServerError.vue')),
        Modal,
        Overlay: defineAsyncComponent(() => import('@/components/Overlay.vue')),
        SplashLoader,
    },

    data() {
        return {
            windowWidth: DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH as number,
            windowHeight: window?.innerHeight,
            hasLoadedKeyRequests: false as boolean,
            mountingError: false as false | string,
        };
    },
    setup() {
        const { supplier } = useSupplier();
        const { captureException } = applicationContainer
            .get<LoggerServiceInterface>(LOGGER_SERVICE);
        const { user } = useUser();
        const {
            getActiveModalView,
            getModalAnchorPoint,
            getModalProperties,
            getModalDisableClosing,
            getModalConfiguration,
            modalIsOpen,
        } = useApplicationState();
        const { toastQueue, removeToast, hasToasts } = useToasts();

        const { twoLettersLocaleCode } = useTranslationService();

        const supplierService = applicationContainer.get<SupplierServiceInterface>(SUPPLIER_SERVICE);
        const { listSuppliers, getCurrentSupplier } = useUserSupplier({ supplierService });

        return {
            supplier,
            captureException,
            getActiveModalView,
            getModalAnchorPoint,
            getModalConfiguration,
            modalIsOpen,
            getModalProperties,
            getModalDisableClosing,
            toastQueue,
            removeToast,
            hasToasts,
            user,
            twoLettersLocaleCode,
            listSuppliers,
            getCurrentSupplier,
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateWindowSize);
        this.updateWindowSize();
        this.getUserInfo().then(() => {
            bootIntercom(this.user, this.supplier as SupplierType, this.twoLettersLocaleCode);
        }).catch((exception:Error) => {
            this.mountingError = this.captureException(exception);
        });
        this.setSwitchSupplierExistence();
        try {
            this.$setCoppiLocale(this.twoLettersLocaleCode);
        } catch (e) {
            this.captureException(e);
        }
    },
    computed: {
        applicationKey():string {
            return this.supplier?.id ?? '';
        },
        isLandScapedMode():boolean {
            return (window?.screen?.orientation?.angle === 90 || window?.screen?.orientation?.angle === -90) && this.windowHeight < DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH;
        },
        getCurrentViewPort():string {
            return this.windowWidth < DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH || this.isLandScapedMode || this.windowWidth < this.windowHeight ? VIEWPORT.MOBILE : VIEWPORT.DESKTOP;
        },
        showDesktopLayout():boolean {
            const isDesktopLayout:boolean = (this.$route.name && this.getCurrentViewPort === VIEWPORT.DESKTOP && this.hasLoadedKeyRequests) as boolean;
            setIsDesktopLayout(isDesktopLayout);
            return isDesktopLayout;
        },
        showMobileLayout():boolean {
            return (this.getCurrentViewPort === VIEWPORT.MOBILE && this.$route.name && this.hasLoadedKeyRequests) as boolean;
        },
        locked() {
            return !!(this.getActiveModalView || this.getModalConfiguration.component);
        },
    },
    methods: {
        updateWindowSize():void {
            this.windowWidth = window?.innerWidth;
            this.windowHeight = window?.innerHeight;
        },
        async setSwitchSupplierExistence() :Promise<void> {
            try {
                await this.listSuppliers();
            } catch (exception) {
                this.mountingError = this.captureException(exception);
            }
        },
        async getUserInfo():Promise<void> {
            try {
                await this.getCurrentSupplier();
                this.hasLoadedKeyRequests = true;
            } catch (exception) {
                console.log('Error while mounting main application', exception);
                throw exception;
            }
        },
    },
});

</script>
<style>
:root {
  --sidebar-width: 257px;
  --mobile-menu-height: 101px;
}
</style>
