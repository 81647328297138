import { computed, ref } from 'vue';
import { IUseToasts, ToastMessage } from '@/components/Toasts/IUseToasts';

const toastQueue = ref<ToastMessage[]>([]);

export default function useToasts(): IUseToasts {
    const addToast = (toast: ToastMessage) => {
        toastQueue.value.push(toast);
    };

    // TODO refactor this to take the toast as argument
    const removeToast = (index: number) => {
        toastQueue.value.splice(index, 1);
    };

    const hasToasts = computed(() => toastQueue.value.length > 0);

    return {
        addToast,
        removeToast,
        hasToasts,
        // TODO refactor this to export it as computed (readonly)
        toastQueue,
    };
}
