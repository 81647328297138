import Keycloak from 'keycloak-js';

import {
    AUTH_SERVICE_URL, AUTH_REALM, SKIP_CHECK_LOGIN_ON_INIT, AUTH_SERVICE_CLIENT,
} from '@/bootstrap/environment';
import { NotInitialisedError } from '@/services/KeycloackService/Errors/NotInitialisedError';
import { injectable } from 'inversify';
import { RefreshTokenError } from '@/services/KeycloackService/Errors/RefreshTokenError';
import { BookingkitRoleType } from '@/constants/roles';

@injectable()
export class KeycloakServiceImplementation implements AuthenticationServiceInterface {
  private keyCloakApi: Keycloak | undefined;

  constructor() {
      this.logout = this.logout.bind(this);
      this.hasRole = this.hasRole.bind(this);
  }

  async init(Constructor = Keycloak) {
      try {
          this.keyCloakApi = new Constructor({
              realm: AUTH_REALM,
              clientId: AUTH_SERVICE_CLIENT,
              url: AUTH_SERVICE_URL,
          });
          this.keyCloakApi.onTokenExpired = () => {
              this.updateToken(60).catch((e) => {
                  if (e instanceof RefreshTokenError) {
                      this.logout();
                  } else { throw e; }
              });
          };
          const kcInitOption: {[key: string]: string | boolean | number} = {
              checkLoginIframe: false,
          };
          if (!SKIP_CHECK_LOGIN_ON_INIT) {
              kcInitOption.onLoad = 'login-required';
              kcInitOption.checkLoginIframe = true;
          }

          return this.keyCloakApi.init(kcInitOption);
      } catch (e) {
          return Promise.reject(e instanceof Error ? e : new Error('Unknown error'));
      }
  }

  async loadUserInfo(): Promise<unknown> {
      if (!this.keyCloakApi) {
          throw new NotInitialisedError();
      }
      return this.keyCloakApi.loadUserInfo();
  }

  async isTokenExpired(): Promise<boolean> {
      if (!this.keyCloakApi) {
          throw new NotInitialisedError();
      }
      return this.keyCloakApi.isTokenExpired();
  }

  async updateToken(minValidityInSeconds: number): Promise<void> {
      if (!this.keyCloakApi) {
          throw new NotInitialisedError();
      }
      return this.keyCloakApi.updateToken(minValidityInSeconds)
          .then(() => Promise.resolve())
          .catch((e) => {
              if (e instanceof Error) {
                  return Promise.reject(e);
              }
              return Promise.reject(new RefreshTokenError());
          });
  }

  hasRole(role: BookingkitRoleType): boolean {
      if (!this.keyCloakApi) {
          throw new NotInitialisedError();
      }
      return this.keyCloakApi.hasRealmRole(role);
  }

  getToken(): string {
      if (!this.keyCloakApi) {
          throw new NotInitialisedError();
      }
      if (!this.keyCloakApi.authenticated) {
          return '';
      }
      return this.keyCloakApi.token as string;
  }

  logout():Promise<void> {
      if (!this.keyCloakApi) {
          throw new NotInitialisedError();
      }
      return this.keyCloakApi.logout();
  }
}

export default KeycloakServiceImplementation;
