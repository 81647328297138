import { settablePaymentMethods } from '@/modules/sell/pages/PaymentMethods/constants';

export enum PaymentMethodSettingTypeEnum {
    TEXT = 'text',
    EMAIL = 'email',
    DATE = 'date',
    BOOLEAN = 'boolean',
}

export type PaymentMethodSettingsType = {
  type: PaymentMethodSettingTypeEnum,
  value: string | boolean,
  validationRules?: string[],
  hasError?: boolean;
  fieldName?: string;
  required?: boolean;
}

export type PaymentMethodExperienceFilterType = {
  activeForAllExperiences: boolean,
  activeForExperiences?: number[]
}

export type PaymentMethodAdditionalFeesType = {
    variable: number,
}

export type ExperienceFilter = {
  id: number,
  title: string
}

export interface PaymentMethodType {
  id: keyof typeof settablePaymentMethods;
  active: boolean;
  settings?: PaymentMethodSettingsType[];
  experience_filter?: PaymentMethodExperienceFilterType
  additional_fees?: PaymentMethodAdditionalFeesType
}

export type CategorisedPaymentMethodsType = {
  [key: string]: PaymentMethodType[]
};
