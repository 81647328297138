import { PageVisibilityInactiveCallbackError }
    from '@/listeners/Exceptions/PageVisibilityInactiveCallbackError';
import { PageVisibilityActiveCallbackError }
    from '@/listeners/Exceptions/PageVisibilityActiveCallbackError';
import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { LOGGER_SERVICE } from '@/bootstrap/ServiceProviders';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { BkNextDomEventListenerType } from '@/listeners/constants';

type VisibilityCallback = ()=>void

const onPageActiveCallbacks: VisibilityCallback[] = [];
const onPageIdleCallbacks: VisibilityCallback[] = [];
const { captureException } = applicationContainer.get<LoggerServiceInterface>(LOGGER_SERVICE);
const runPageActiveCallbacks = async () => onPageActiveCallbacks
    .reduce(async (allResolvedCallbacks, callback) => {
        try {
            const resolveCallbacks = await allResolvedCallbacks;
            await callback();
            return resolveCallbacks;
        } catch (e) {
            captureException(new PageVisibilityActiveCallbackError(e));
            return allResolvedCallbacks;
        }
    }, Promise.resolve({}));

const runPageInactiveCallbacks = async () => onPageIdleCallbacks
    .reduce(async (allResolvedCallbacks, callback) => {
        try {
            const resolveCallbacks = await allResolvedCallbacks;
            await callback();
            return resolveCallbacks;
        } catch (e) {
            captureException(new PageVisibilityInactiveCallbackError(e));
            return allResolvedCallbacks;
        }
    }, Promise.resolve({}));

export const registerPageBackActiveCallback = (callback: VisibilityCallback):void => {
    onPageActiveCallbacks.push(callback);
};

export const registerOnPageIdleCallback = (callback: VisibilityCallback):void => {
    onPageIdleCallbacks.push(callback);
};

export const init = ():void => {
    document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
            runPageInactiveCallbacks()
                .catch((e) => {
                    captureException(new PageVisibilityInactiveCallbackError(e));
                });
        } else {
            runPageActiveCallbacks()
                .catch((e) => {
                    captureException(new PageVisibilityActiveCallbackError(e));
                });
        }
    });
};

export default {
    mount: init,
    unmount: init,
} as BkNextDomEventListenerType;
