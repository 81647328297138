import useApplicationState from '@/state/application';
import useUser from '@/state/user';

let toastDisplayTimeOut: NodeJS.Timeout;

const displayShortcutToastMessage = () => {
    const { activateShortcutActionToast, activateShortcutInfoToast, isToastShortcutMessageAlreadyShown } = useApplicationState();
    if (isToastShortcutMessageAlreadyShown()) {
        return;
    }
    toastDisplayTimeOut = setTimeout(() => {
        activateShortcutInfoToast({
            active: true,
            message: navigator.userAgent.includes('Mac') ? 'powerShortcuts.vendorShortcutToastInfoMac' : 'powerShortcuts.vendorShortcutToastInfoWindows',
        });
        activateShortcutActionToast();
    }, 1000);
};

const callback = (event: Pick<KeyboardEvent, 'preventDefault'>) => {
    const {
        isSupplierSwitchModalActive, activateSupplierSwitchModal, closeModal,
        isPowerShortcutsEnabled,
    } = useApplicationState();
    const { user } = useUser();
    if (!isPowerShortcutsEnabled()) {
        return;
    }
    if (!isSupplierSwitchModalActive() && user.value?.canSwitchSupplier) {
        closeModal();
        activateSupplierSwitchModal();
        displayShortcutToastMessage();
        event.preventDefault();
    } else {
        if (toastDisplayTimeOut) {
            clearTimeout(toastDisplayTimeOut);
        }
        closeModal();
    }
};

const windows = {
    callback,
    keys: ['control', 'm'],
};

const mac = {
    callback,
    keys: ['m', 'meta'],
};

export default {
    windows, mac,
};
