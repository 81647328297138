export const Identifier = {
    mounted(element: unknown, bindings: any) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        element.dataset.cy = bindings.value;
    },
};

export default Identifier;
