import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { UseSupplierInterface } from '@/state/supplier';
import { AppServiceError } from '@/services/AppsService/Errors/AppServiceError';
import { fromApiApp } from '@/services/AppsService/Reducers';
import { BkAppType } from '@/services/AppsService/types';

@injectable()
export class BkAppsService {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    private supplierId: string;

    constructor(
        @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
        @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
    ) {
        const { supplier } = useSupplierState();
        if (!supplier.value?.id) {
            throw AppServiceError.fromMissingSupplier();
        }
        this.supplierId = supplier.value.id;
        this.connection = factory.getConnection();
        this.listApps = this.listApps.bind(this);
    }

    async listApps(pageSize = 10): Promise<BkAppType[]> {
        const { supplierId } = this;
        const { data } = await this.connection.listApps(supplierId, pageSize);
        if (!data) {
            throw AppServiceError.fromMalformedResponse('data');
        }
        const appsList = data.data;
        if (!appsList) {
            throw AppServiceError.fromMalformedResponse('data.data');
        }
        return appsList.map(fromApiApp);
    }
}

export default { BkAppsService };
