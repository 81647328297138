export class UserServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UserServiceError);
        }

        this.name = 'UserServiceError';
    }

    static fromMissingProperty(property:string): UserServiceError {
        return new UserServiceError(`Property ${property} is missing in the data`);
    }
}

export default UserServiceError;
