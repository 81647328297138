{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Aggiungi appuntamento",
    "downloadChecklist": "Check-list",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Nessuna esperienza abbinata",
        "noExperiences": "Nessuna esperienza abbinata",
        "noInactiveExperienceMatchingFilter": "Nessuna esperienza inattiva abbinata"
      }
    },
    "warnings": {
      "noExperiences": "Nessuna esperienza abbinata"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Azioni rapide"
        }
      },
      "navAccount": {
        "help": {
          "label": "Guida"
        },
        "switch": {
          "label": "Passa a"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "giorno | giorni",
    "hours": "ora | ore",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Vai alla panoramica",
        "description": "Il tuo account non ha i diritti necessari per visualizzare questa pagina",
        "title": "Non hai il permesso di visualizzare questa pagina"
      },
      "serverError": {
        "cta": "Riprova",
        "description": "A causa di un errore interno del server, non siamo stati in grado di completare la tua richiesta. Abbiamo ricevuto una segnalazione al riguardo. Ti invitiamo a riprovare in un secondo momento.",
        "title": "Siamo spiacenti! Si è verificato un errore anomalo"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Il fornitore non ha portafogli attivati"
      }
    },
    "validationErrors": {
      "invalidIban": "IBAN non valido",
      "notAValidEmail": "Il campo non è un'e-mail valida",
      "notAValidMoneyAmount": "Importo di denaro non valido",
      "notEmpty": "Questo campo non può rimanere vuoto",
      "notIdenticalIban": "L'IBAN confermato deve essere lo stesso dell'IBAN",
      "unrecognisedDateTime": "Data e ora non riconosciute"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Scegli a quale esperienza vuoi assegnare la nuova disponibilità.",
      "title": "Aggiungi appuntamento all'esperienza"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Aggiorna",
      "title": "È disponibile una nuova versione di bookingkit"
    }
  },
  "pagination": {
    "next": "Successivo",
    "previous": "Precedente"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Aggiungi appuntamento",
    "disableHere": "Disabilità tasti di scelta rapida qui",
    "enableShortcuts": "Abilita tasti di scelta rapida",
    "keyboardShortcuts": "Scelte rapide tastiera",
    "ok": "OK",
    "powerShortcuts": "Scelte rapide comandi",
    "search": "Cerca",
    "searchShortcutToastInfoMac": "Premi ⌘ + F di nuovo per attivare la ricerca del browser nativo",
    "searchShortcutToastInfoWindows": "Premi Ctrl + F di nuovo per attivare la ricerca del browser nativo",
    "toastMessage": "I tasti di scelta rapida sono abilitati per impostazione predefinita.",
    "vendorShortcutToastInfoMac": "Premi ⌘ + M di nuovo per attivare il tasto di scelta rapida del browser nativo",
    "vendorShortcutToastInfoWindows": "Premi Ctrl + M di nuovo per attivare il tasto di scelta rapida del browser nativo",
    "vendorSwitch": "Cambio fornitore"
  },
  "search": {
    "accepted": "ACCETTATO",
    "archived": "ARCHIVIATO",
    "canceled": "CANCELLATO",
    "coupon": "Coupon",
    "declined": "RIFIUTATO",
    "expired": "SCADUTO",
    "failure": "ERRORE",
    "feature": "Funzionalità",
    "flexibleTicket": "Biglietto flessibile",
    "invalid": "Non valido",
    "noResultFound": "Nessun risultato trovato",
    "noResultFoundMessage": "La tua ricerca non ha prodotto risultati. Riprova in un altro modo.",
    "open": "APRI",
    "order": "Ordine",
    "pending": "IN SOSPESO",
    "reserved": "RISERVATO",
    "search": "Cerca",
    "searchEntryMessage": "Puoi cercare:",
    "searchError": "Si è verificato un errore.",
    "searchErrorMessage": "Stiamo lavorando per risolvere il problema. Riprova a breve.",
    "searchFeatureEntryMessage": "Caratteristiche per nome.",
    "searchOrderEntryMessage": "ordini in base all'ID, al cliente o ad altri dati relativi all'ordine",
    "searchScopes": {
      "all": "Tutti",
      "coupons": "Coupon",
      "features": "Funzionalità",
      "flexibleTickets": "Biglietti flessibili",
      "orders": "Ordini",
      "vouchers": "Buoni"
    },
    "searchVoucherEntryMesaage": "Buoni per codice, titolo o descrizione;",
    "searchVoucherEntryMessage": "buoni per codice, titolo o descrizione;",
    "ticketTypes": {
      "all": "Tutti",
      "coupons": "Coupon",
      "features": "Funzionalità",
      "flexibleTickets": "Biglietti flessibili",
      "orders": "Ordini",
      "vouchers": "Buoni"
    },
    "valid": "Valido",
    "voucher": "Buono"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Dati aziendali"
      },
      "contract": {
        "label": "Contratto"
      },
      "main": {
        "label": "account"
      },
      "profile": {
        "label": "Profilo"
      },
      "signOut": {
        "label": "Esci"
      },
      "userManagement": {
        "label": "Gestione utenti"
      },
      "vendorSwitcher": {
        "label": "selezione fornitore"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "Pagamenti API"
      },
      "applicaitonLimits": {
        "label": "Limiti dell'applicazione"
      },
      "application": {
        "label": "Applicazione"
      },
      "applicationLimits": {
        "label": "Limiti dell'applicazione"
      },
      "changeRates": {
        "label": "Modifica tariffe"
      },
      "chargeVouchers": {
        "label": "Addebita buoni"
      },
      "claimAccount": {
        "label": "Assegna account"
      },
      "createAccount": {
        "label": "Crea account"
      },
      "dashboard": {
        "label": "Dashboard"
      },
      "disableEventDates": {
        "label": "Disabilita date eventi"
      },
      "emailConfiguration": {
        "label": "Configurazione e-mail"
      },
      "importCoupons": {
        "label": "Importa coupon"
      },
      "importOrders": {
        "label": "Importa ordini"
      },
      "importVouchers": {
        "label": "Importa buoni"
      },
      "inviteNew": {
        "label": "Invita un nuovo rivenditore"
      },
      "invoices": {
        "label": "Fatture"
      },
      "KYCUnverified": {
        "label": "KYC non verificato"
      },
      "main": {
        "label": "Amministratore"
      },
      "manageAPI": {
        "label": "Gestisci API"
      },
      "manageBkUsers": {
        "label": "Gestisci utenti bk"
      },
      "mangopayDisputes": {
        "label": "Controversie Mangopay"
      },
      "marketingPartners": {
        "label": "Partner di marketing"
      },
      "outstandingPayments": {
        "label": "Pagamenti in sospeso"
      },
      "paymentRequests": {
        "label": "Richieste di pagamento"
      },
      "payoutChannels": {
        "label": "Canali di pagamento"
      },
      "paypalPaymentRequests": {
        "label": "Richieste di pagamento Paypal"
      },
      "platforms": {
        "label": "Piattaforme"
      },
      "provider": {
        "label": "Fornitore"
      },
      "rates": {
        "label": "Tariffe"
      },
      "resellerPaymentStatus": {
        "label": "Stato del pagamento del rivenditore"
      },
      "setupFee": {
        "label": "Tariffa di configurazione"
      },
      "translationTool": {
        "label": "Strumento di traduzione"
      },
      "unknownPaymentrequests": {
        "label": "Richieste di pagamento sconosciute"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Visualizzazione quotidiana"
      },
      "feed": {
        "label": "Feed calendario"
      },
      "main": {
        "label": "Calendario"
      },
      "month": {
        "label": "Visualizzazione mensile"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Pacchetti"
      },
      "experiences": {
        "label": "Esperienze"
      },
      "flexibleOffers": {
        "label": "Offerte flessibili"
      },
      "main": {
        "label": "Inventario"
      },
      "products": {
        "label": "Prodotti"
      },
      "resources": {
        "label": "Risorse"
      }
    },
    "marketplace": {
      "main": {
        "label": "App Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Biglietti flessibili"
      },
      "main": {
        "label": "Ordini"
      },
      "orders": {
        "label": "Ordini"
      },
      "participants": {
        "label": "Partecipanti"
      },
      "promotionalCoupons": {
        "label": "Coupon promozionali"
      },
      "requests": {
        "label": "Richieste"
      },
      "vouchers": {
        "label": "Buoni"
      }
    },
    "overview": {
      "main": {
        "label": "Panoramica"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Link di affiliazione"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Invita un nuovo rivenditore"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "I miei partner"
      },
      "partnerRates": {
        "label": "Tariffe per i partner"
      },
      "platforms": {
        "label": "Piattaforme"
      },
      "promote": {
        "label": "Promuovere esperienze locali"
      },
      "reachOrders": {
        "label": "Ordini"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Statistiche aggregate"
      },
      "exports": {
        "label": "Esportazioni"
      },
      "main": {
        "label": "Rapporti"
      },
      "monthlyStatement": {
        "label": "Estratto mensile"
      },
      "statements": {
        "label": "Estratti"
      },
      "statistics": {
        "label": "Statistiche"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Rivenditori"
      },
      "marketplace": {
        "label": "Markerplace"
      },
      "myPartners": {
        "label": "I miei partner"
      },
      "orders": {
        "label": "Ordini"
      },
      "platforms": {
        "label": "Integrazioni dei rivenditori"
      },
      "promote": {
        "label": "Promuovi esperienze locali"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Configurazione della cassa"
      },
      "emailCampaigns": {
        "label": "Campagne e-mail"
      },
      "localExperiences": {
        "label": "Esperienze locali"
      },
      "main": {
        "label": "Vendi"
      },
      "newWidgets": {
        "label": "Nuovi widgets"
      },
      "paymentOptions": {
        "label": "Opzioni di pagamento"
      },
      "paymentProviders": {
        "label": "Prestatori di servizi di pagamento"
      },
      "widgets": {
        "label": "Widget"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Feed calendario"
      },
      "emailConfiguration": {
        "label": "Configurazione e-mail"
      },
      "invoiceLayout": {
        "label": "Layout fattura"
      },
      "invoiceNNumber": {
        "label": "Numero della fattura"
      },
      "invoiceNumber": {
        "label": "Numero della fattura"
      },
      "main": {
        "label": "Impostazioni"
      },
      "modules": {
        "label": "Moduli"
      },
      "ticketConfiguration": {
        "label": "Configurazione biglietto"
      },
      "translations": {
        "label": "Traduzioni"
      },
      "vat": {
        "label": "IVA"
      },
      "voucherConfiguration": {
        "label": "Configurazione buono"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App Marketplace"
      },
      "appointment": {
        "label": "Aggiungi appuntamento"
      },
      "booking": {
        "label": "Prenotazione"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Contatta l'assistenza"
      },
      "copyToClipboard": {
        "failure": "Impossibile copiare il collegamento per il fornitore",
        "success": "Link specifico copiato per il fornitore:"
      },
      "date": {
        "label": "Data"
      },
      "experience": {
        "label": "Esperienza"
      },
      "knowledgeBase": {
        "label": "Knowledge base",
        "url": "https://help.bookingkit.com/it/"
      },
      "onboardingWizard": {
        "label": "Procedura guidata di onboarding (da confermare)"
      },
      "powerShortcuts": {
        "label": "Scelte rapide comandi"
      }
    },
    "updates": {
      "main": {
        "label": "Aggiornamenti del prodotto"
      },
      "mainBadge": {
        "label": "Nuovo"
      }
    },
    "wallet": {
      "main": {
        "label": "Portafoglio"
      },
      "partnerPayments": {
        "label": "Pagamenti dei partner"
      },
      "verification": {
        "label": "Verifica"
      },
      "walletManagement": {
        "label": "Gestione del portafoglio"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Fornitori disponibili",
    "dismiss": "Annulla",
    "navigate": "Naviga",
    "noResult": "Nessun risultato trovato",
    "recentUsed": "Usato di ricente",
    "resultsLabel": "Fornitori trovati",
    "searchError": "Si è verificato un errore.",
    "searchErrorMessage": "Stiamo lavorando per risolvere il problema. Riprova a breve.",
    "searchForVendor": "Cerca il fornitore",
    "select": "Seleziona",
    "storeResultsLabel": "Fornitori disponibili",
    "supplierAlreadyInUseError": "Stai utilizzando questo account fornitore",
    "switchVendor": "Cambia fornitore",
    "wishmorning": "Buongiorno"
  }
}