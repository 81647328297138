export class InventoryServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InventoryServiceError);
        }

        this.name = 'InventoryServiceError';
    }

    static fromMissingSupplier(): InventoryServiceError {
        return new InventoryServiceError('Supplier service was not able to return an identifier for this supplier during initialisation');
    }

    static fromInvalidProperty(property: string, expectedType: string, value: unknown): InventoryServiceError {
        return new InventoryServiceError(`Required property \`${property}\` was invalid. Expected ${expectedType}, got ${typeof value}`);
    }

    static fromInvalidDateString(dateString: string): InventoryServiceError {
        return new InventoryServiceError(`The string \`${dateString}\` is not a valid date.`);
    }
}

export default InventoryServiceError;
