import { VoucherResponse } from '@bookingkit-private/api-v4';
import { VOUCHER, VoucherStatusEnum } from '@/constants';
import { SearchResult } from '@/components/SearchModal/SearchResultType';
import { CurrencyType } from '@/constants/currencySymbols';
import { getStatusIntent, mainInfoHero } from '@/components/SearchModal/searchResultMapper';

export const fromApiVoucherToSearchResultVoucher = (voucher :Partial<VoucherResponse>) : SearchResult => {
    const status = voucher.active ? VoucherStatusEnum.VALID : VoucherStatusEnum.INVALID;
    return {
        scope: VOUCHER,
        name: voucher.title ?? '',
        code: voucher.code ?? '',
        id: voucher.id ?? '',
        orderDate: voucher.creationDate ?? '',
        status,
        headBadges: status
            ? [{ label: VOUCHER }, {
                label: status,
                type: getStatusIntent(status),
            }]
            : [{ label: VOUCHER }],
        mainInfoHero: mainInfoHero({ amount: voucher.residual_value?.value ?? 0, currency: voucher.residual_value?.currency as CurrencyType },
            undefined, status),
    };
};

export default { fromApiVoucherToSearchResultVoucher };
