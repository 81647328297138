import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextRouteInterface } from '@/router/helpers/constants';
import { PRODUCT_UPDATES } from '@/constants/routeNames';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const moduleName = 'productUpdates';

const routes = {
    path: `/${PRODUCT_UPDATES.MAIN}`,
    name: PRODUCT_UPDATES.MAIN,
    label: 'sidenav.updates.main.label',
    component: Home,
    meta: {
        title: 'sidenav.updates.main.label',
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.BOTTOM,
            icon: 'Bell',
        },
        badge: {
            value: 'sidenav.updates.mainBadge.label',
        },
    },

} as unknown as BkNextRouteInterface;

export default getEnrichedRoute(routes, moduleName, getModuleMessages);
