export class PageVisibilityInactiveCallbackError extends Error {
    constructor(...params: any[]) {
        super(...params);
        this.message = `Error while executing the callback: ${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PageVisibilityInactiveCallbackError);
        }

        this.name = 'PageVisibilityInactiveCallbackError';
    }
}

export default PageVisibilityInactiveCallbackError;
