import { Container } from 'inversify';
import { UserService } from '@/services/UserService';
import {
    AUTHENTICATION_SERVICE,
    BOOKINGKIT_API_FACTORY,
    LOGGER_SERVICE,
    USER_SERVICE,
    SUPPLIER_STATE_SERVICE,
    ACCOUNTING_SERVICE,
    INVENTORY_SERVICE,
    SUPPLIER_SERVICE,
    AUTHORIZATION_SERVICE,
    ORDER_SERVICE,
    VOUCHER_SERVICE,
    USER_STATE_SERVICE,
    COUPON_SERVICE,
    MODULE_SERVICE, BK_APPS_SERVICE, WIDGET_SERVICE,
} from '@/bootstrap/ServiceProviders';
import { DEVELOPMENT, UI_TESTING } from '@/constants';

import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';

import consoleLoggerService from '@/services/ConsoleLoggerService/ConsoleLoggerService';
import sentryService from '@/services/SentryService/SentryService';
import { BookingKitApiFactory } from '@/Factories/BookingkitApi';
import { KeycloakServiceImplementation as KeycloakService } from '@/services/KeycloackService';

import { useSupplier, UseSupplierInterface } from '@/state/supplier';
import { AccountingService } from '@/services/AccountingService';
import { InventoryService } from '@/services/InventoryService/inventoryService';
import { NODE_ENV } from '@/bootstrap/environment';
import { SupplierService } from '@/services/SupplierService';
import { SupplierServiceInterface } from '@/models/Services/SupplierServiceInterface';
import { UserServiceInterface } from '@/models/UserServiceInterface';
import { AuthorisationService } from '@/services/AuthorisationService/AuthorisationService';
import { OrderService } from '@/services/OrderService/OrderService';
import { VoucherService } from '@/services/VoucherService/VoucherService';
import useUser, { UseUserInterface } from '@/state/user';
import { CouponService } from '@/services/CouponService/CouponService';
import { ModuleService } from '@/services/ModuleService/ModuleService';
import { BkAppsService } from '@/services/AppsService/BkAppsService';
import { WidgetService } from '@/services/WidgetService/WidgetService';

export const applicationContainer = new Container();

applicationContainer
    .bind<AuthenticationServiceInterface>(AUTHENTICATION_SERVICE)
    .to(KeycloakService)
    .inSingletonScope();

applicationContainer
    .bind<AuthorisationService>(AUTHORIZATION_SERVICE)
    .to(AuthorisationService);

applicationContainer
    .bind<() => UseSupplierInterface>(SUPPLIER_STATE_SERVICE)
    .toFunction(useSupplier);

applicationContainer
    .bind<() => UseUserInterface>(USER_STATE_SERVICE)
    .toFunction(useUser);

// Services
applicationContainer.bind<UserServiceInterface>(USER_SERVICE)
    .to(UserService);

applicationContainer.bind<SupplierServiceInterface>(SUPPLIER_SERVICE)
    .to(SupplierService);

applicationContainer.bind<AccountingService>(ACCOUNTING_SERVICE)
    .to(AccountingService);

applicationContainer.bind<InventoryService>(INVENTORY_SERVICE)
    .to(InventoryService);

applicationContainer.bind<OrderService>(ORDER_SERVICE)
    .to(OrderService);

applicationContainer.bind<VoucherService>(VOUCHER_SERVICE)
    .to(VoucherService);

applicationContainer.bind<WidgetService>(WIDGET_SERVICE)
    .to(WidgetService);

applicationContainer.bind<CouponService>(COUPON_SERVICE)
    .to(CouponService);

applicationContainer.bind<ModuleService>(MODULE_SERVICE)
    .to(ModuleService);

applicationContainer.bind<BkAppsService>(BK_APPS_SERVICE)
    .to(BkAppsService);

if (NODE_ENV === DEVELOPMENT || NODE_ENV === UI_TESTING) {
    applicationContainer.bind<LoggerServiceInterface>(LOGGER_SERVICE)
        .to(consoleLoggerService).inSingletonScope();
} else {
    applicationContainer.bind<LoggerServiceInterface>(LOGGER_SERVICE)
        .to(sentryService).inSingletonScope();
}

// Factories
applicationContainer.bind<BookingKitApiFactoryInterface>(BOOKINGKIT_API_FACTORY)
    .to(BookingKitApiFactory);

export default { applicationContainer };
