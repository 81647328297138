import {
    ref, computed, ComputedRef, watch,
} from 'vue';
import { SupplierType } from '@/models/User';

export interface UseSupplierInterface {
    setSupplier: (supplierData: SupplierType) => void,
    supplier: ComputedRef<SupplierType | undefined>,
    defaultSupplierList?: ComputedRef<SupplierType[]>,
    setDefaultSupplierList?: (supplierList: SupplierType[]) => void,
    isReady: () => Promise<void>,
}

const currentSupplier = ref<SupplierType>();
const defaultSupplierList = ref<SupplierType[]>([]);

export const setSupplier = (supplierData: SupplierType):void => {
    currentSupplier.value = supplierData;
};

export const setDefaultSupplierList = (supplierList:SupplierType[]):void => {
    defaultSupplierList.value = supplierList;
};

export const isReady = ():Promise<void> => {
    if (currentSupplier.value) {
        return Promise.resolve();
    }
    return new Promise((resolve) => {
        const watcher = watch(currentSupplier, () => {
            if (currentSupplier.value) {
                resolve();
                watcher();
            }
        });
    });
};

export const useSupplier = (): UseSupplierInterface => ({
    supplier: computed(() => currentSupplier.value),
    setSupplier,
    defaultSupplierList: computed(() => defaultSupplierList.value),
    setDefaultSupplierList,
    isReady,
});

export default useSupplier;
