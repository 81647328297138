import { SupplierScopeType } from '@/models/User';
import { Component } from 'vue';
import { ModuleType } from '@/models/Modules';
import { RouteLocation, RouteLocationRaw } from 'vue-router';

export type BkNextRouteMeta = {
    moduleName: string,
    loadModuleAssets: (locale: ApplicationLocale) => Promise<{ default: any }>,
    manticoreMatches?: RegExp[],
    title?:string,
    sidenav: {
        parent?: boolean,
        position: string,
        icon?: string
    }
    modulesToBeActive?:ModuleType[],
    scopesRequired?: SupplierScopeType[],
    logout?: boolean,
    iFrameUrl?: string,
}

export interface BkNextRouteInterface {
    path: string,
    hiddenRoutes?: string[],
    name?: string | symbol,
    component?: Component | null,
    props?: any,
    redirect?: RouteLocationRaw | ((to: RouteLocation) => RouteLocationRaw),
    label?: string,
    children?: BkNextRouteInterface[]
    'data-cy'?: string,
    meta?: Partial<BkNextRouteMeta>,
    address?: {
        url:string,
        openNewTab:boolean
    }
}

export interface BkNextMainModuleRouteInterface {path: string,
    name: string | symbol,
    label?: string,
    component?: Component,
    meta: BkNextRouteMeta,
    'data-cy'?: string,
    children?: BkNextRouteInterface[],
    props?: any,
    redirect?: string
}

export const SIDEBAR_POSITIONS = {
    BOTTOM: 'nav-bottom',
    TOP: 'nav-top',
    ACCOUNT: 'nav-account',
    QUICK_ACTIONS_SHORTCUTS: 'quick-actions-shortcuts',
};
