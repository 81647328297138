export class ApiV4BaseError extends Error {
    public code: string;

    public details: Record<string, any>;

    constructor(message: string, code?: string, details?: Record<string, any>) {
        super(message);
        this.message = `${this.message}`;
        this.code = code ?? '';
        this.details = details ?? {};
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiV4BaseError);
        }

        this.name = 'ApiV4BaseError';
    }

    public getDetails() {
        return this.details;
    }
}

export default ApiV4BaseError;
