<template>
    <div :class="walletPageStyles" class="wallet-router-view">
        <router-view />
    </div>
</template>

<script lang="ts" setup>
import { WALLET_ROUTES_NAMES } from '@/constants';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { BkNextRouteInterface } from '@/router/helpers/constants';

const router = useRouter() as unknown as BkNextRouteInterface;

const walletPageStyles = computed(() => {
    if (router.path === WALLET_ROUTES_NAMES.WALLET) {
        return 'walletManagement-page-route';
    }
    return '';
});

</script>

<style scoped>
.walletManagement-page-route {
  display: grid;
  grid-gap: var(--s4);
  grid-template-columns: [full-start] 0 [main-start] repeat(4, 1fr) [main-end] 0 [full-end];
  width: 100%;
}

.wallet-router-view {
  padding: 0 var(--s1);
}

@media screen and (min-width: 769px) {
  .walletManagement-page-route {
    grid-gap: var(--s6);
    grid-template-columns: [full-start] 0 [main-start] repeat(8, 1fr) [main-end] 0 [full-end];
  }

  .wallet-router-view {
    padding: 0 var(--s4);
  }
}

@media screen and (min-width: 1060px) {
  .walletManagement-page-route {
    grid-template-columns: [full-start] 0 [main-start] repeat(12, 1fr) [main-end] 0 [full-end];
  }
}

@media screen and (min-width: 1440px) {
  .walletManagement-page-route {
    grid-template-columns: [full-start] minmax(calc(calc(100% - 1184px) / 2), 1fr) [main-start] repeat(12, 1fr) [main-end] minmax(calc(calc(100% - 1184px) / 2), 1fr) [full-end];
  }
}
</style>
