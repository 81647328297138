import {
    PaymentProvider as ApiPaymentProvider,
    PaymentProviderStatusEnum as ApiPaymentProviderStatusEnum,
} from '@bookingkit-private/api-v4';
import {
    ActivationIntentTypeEnum,
    KYCStatusEnum,
    PaymentProvider,
    PaymentProviderActivationIntent,
    PaymentProviderStatusEnum,
} from '@/services/AccountingService/types';
import { AccountingServiceError } from '@/services/AccountingService/Errors/AccountingServiceError';
import {
    PaymentProviderActivation,
    PaymentProviderActivationTypeEnum,
} from '@bookingkit-private/api-v4/models/payment-provider-activation';
import { PaymentProviderKycStatusEnum } from '@bookingkit-private/api-v4/models/payment-provider';

const mapIntent = (intent?: PaymentProviderActivation)
    : PaymentProviderActivationIntent | undefined => {
    if (!intent) {
        return undefined;
    }
    // noinspection SuspiciousTypeOfGuard
    if (typeof intent.type !== 'string') {
        throw AccountingServiceError.fromMissingProperty('activation_intent.type');
    }
    if (typeof intent.date !== 'string' && intent.type === PaymentProviderActivationTypeEnum.DateBased) {
        throw AccountingServiceError.fromMissingProperty('activation_intent.date');
    }
    const type = ((apiType) => {
        switch (apiType) {
        case PaymentProviderActivationTypeEnum.DateBased:
            return ActivationIntentTypeEnum.DATE_BASED;
        case PaymentProviderActivationTypeEnum.Asap:
            return ActivationIntentTypeEnum.ASAP;
        default:
            throw AccountingServiceError.fromMissingProperty('activation_intent.type');
        }
    })(intent.type);

    return {
        type,
        date: intent.date,
    };
};

const mapKycStatus = (status: PaymentProviderKycStatusEnum): KYCStatusEnum => {
    switch (status) {
    case PaymentProviderKycStatusEnum.Verified:
        return KYCStatusEnum.VERIFIED;
    case PaymentProviderKycStatusEnum.Unverified:
        return KYCStatusEnum.UNVERIFIED;
    case PaymentProviderKycStatusEnum.Pending:
        return KYCStatusEnum.PENDING;
    case PaymentProviderKycStatusEnum.ActionRequired:
        return KYCStatusEnum.ACTION_REQUIRED;
    case PaymentProviderKycStatusEnum.Rejected:
        return KYCStatusEnum.REJECTED;
    default:
        throw AccountingServiceError.fromMissingProperty('KYC status');
    }
};

const mapStatus = (status: ApiPaymentProviderStatusEnum): PaymentProviderStatusEnum => {
    switch (status) {
    case ApiPaymentProviderStatusEnum.Active:
        return PaymentProviderStatusEnum.ACTIVE;
    case ApiPaymentProviderStatusEnum.Inactive:
        return PaymentProviderStatusEnum.INACTIVE;
    case ApiPaymentProviderStatusEnum.Scheduled:
        return PaymentProviderStatusEnum.SCHEDULED;
    default:
        throw AccountingServiceError.fromMissingProperty('Payment provider status');
    }
};

export const fromApiPaymentProviderToApplicationPaymentProvider = (provider: ApiPaymentProvider)
    : PaymentProvider => {
    if (!provider) {
        throw AccountingServiceError.fromMissingProperty('Payment provider');
    }

    return {
        id: provider.id,
        kycStatus: mapKycStatus(provider.kyc_status),
        status: mapStatus(provider.status),
        activationIntent: mapIntent(provider.activation_intent),
    };
};

export default { fromApiPaymentProviderToApplicationPaymentProvider };
