import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { MODULE_SERVICE } from '@/bootstrap/ServiceProviders';
import { ModuleService } from '@/services/ModuleService/ModuleService';
import { computed, ref } from 'vue';
import { ModuleArrayMapper } from '@/services/ModuleService/types';
import { ModuleServiceError } from '@/services/ModuleService/Errors/ModuleServiceError';
import useUser from '@/state/user';
import { MODULE_AGGREGATE_STATISTICS } from '@/models/Modules';

const allModules = ref<ModuleArrayMapper[]>([]);
const { user } = useUser();

export const useActiveModuleService = () => {
    const { listModules } = applicationContainer.get<ModuleService>(MODULE_SERVICE);
    const getListModules = async () => {
        try {
            allModules.value = await listModules(40);
        } catch (e) {
            // do nothing
        }
        if (user.value.canSwitchSupplier) {
            allModules.value.push({
                id: [MODULE_AGGREGATE_STATISTICS],
                status: 'ENABLED',
            });
        }
        if (!allModules.value) {
            throw ModuleServiceError.fromMissingModules();
        }
    };

    const activeModules = computed(() => allModules.value.filter((m) => m.status === 'ENABLED'));

    return {
        getListModules, activeModules,
    };
};

export default { useActiveModuleService };
