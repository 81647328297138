import { App } from 'vue';
import { PostHogService } from '@/services/PostHogService';

export default {
    install(app:App) {
        const postHogService = new PostHogService(true);
        // NOSONAR
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$posthog = postHogService;
    },
};
