export class AccountingServiceWalletError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AccountingServiceWalletError);
        }

        this.name = 'AccountingServiceWalletError';
    }

    static fromMissingWallet(supplierId:string): AccountingServiceWalletError {
        return new AccountingServiceWalletError(`vendor ${supplierId} has no wallets`);
    }
}

export default AccountingServiceWalletError;
