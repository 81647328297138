/**
 * This file contains the interceptors for the Bookingkit API
 * It is used to handle the responses and errors from the API, generating the correct error classes
 * and logging the errors.
 *
 */
import { AxiosError, AxiosResponse } from 'axios';
import { CanceledRequestError } from '@/Factories/BookingkitApi/Errors/CanceledRequestError';
import { ResponseMissingStatusCodeError } from '@/Factories/BookingkitApi/Errors/ResponseMissingStatusCodeError';
import { ApiV4ClientError } from '@/Factories/BookingkitApi/Errors/ApiV4ClientError';
import { ApiV4ServerError } from '@/Factories/BookingkitApi/Errors/ApiV4ServerError';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { ValidationError } from '@/Factories/BookingkitApi/Errors/ValidationError';
import { IgnorableNetworkError } from '@/Factories/BookingkitApi/Errors/IgnorableNetworkError';

export const onFullFilled = (response: AxiosResponse) => response;

const NOT_REPORTED_ERRORS = [
    CanceledRequestError,
    ValidationError,
    ApiV4ClientError,
    IgnorableNetworkError,
];

const getErrorFromCode = (error: AxiosError) => {
    if (!error.response?.status) {
        return ApiV4ServerError.fromAxiosError(error);
    }
    if (error.response.status === 422) {
        return ValidationError.fromAxiosError(error);
    }
    if (error.response.status >= 400 && error.response?.status < 500) {
        return ApiV4ClientError.fromAxiosError(error);
    }
    return ApiV4ServerError.fromAxiosError(error);
};

const parseError = (error?: any): Error => {
    if (error?.message === 'Network Error' && error?.response === undefined && ((error as AxiosError)?.code === '0' || (error as AxiosError)?.code === undefined)) {
        let isOnline = true;

        try {
            // Navigator can tell us if is offline
            isOnline = window.navigator.onLine;
        } catch {
            // but can rarely trow in some browser,
            // so better to catch the error straight away
            return ResponseMissingStatusCodeError.fromNetworkError(error);
        }
        if (isOnline) {
            // If the client is online but the request fails before reaching the EP,
            // It must be blocked from some extension / dev tool / network problem
            return ResponseMissingStatusCodeError.fromNetworkError(error);
        }

        // if we reach here, means we are offline
        return ResponseMissingStatusCodeError.fromOfflineClient(error);
    }

    if (error?.message === 'canceled') {
        return CanceledRequestError.fromCanceledRequest();
    }

    // This is the case when the request reached the server
    // but the server responded with an error
    if (error?.response?.status) {
        return getErrorFromCode(error);
    }

    // We can log only what we want to log
    if (error instanceof Error) {
        return error;
    }

    return new Error('Unknown error');
};

const shouldReport = (error: Error) => !NOT_REPORTED_ERRORS.some((errorType) => error instanceof errorType);
const logError = (logger: LoggerServiceInterface, error: Error) => {
    if (shouldReport(error)) {
        logger.captureException(error);
    }
};

export const createOnRejected = (logger: LoggerServiceInterface) => (error?: any): Promise<any> => {
    const parsedError = parseError(error);
    logError(logger, parsedError);
    return Promise.reject(parsedError);
};
