import {
    MoneyResponse,
    OrderSearchResultResponse,
    OrderSearchResultResponseOrderItemsInner,
    OrderProductResponseStatusEnum,
    OrderSearchResultResponseOrderItemsInnerOrderItemTypeEnum,
    OrderTicketResponse,
    OrderProductResponse,
    OrderFlexibleTicketResponseOrderItemTypeEnum,
    OrderFlexibleTicketResponse,
} from '@bookingkit-private/api-v4';
import { ORDER, OrderStatusEnum } from '@/constants';
import {
    SearchResult,
} from '@/components/SearchModal/SearchResultType';
import { currencySymbol, CurrencyType } from '@/constants/currencySymbols';
import { OrderServiceError } from '@/services/OrderService/Errors/OrderServiceError';
import {
    ORDER_ITEM_TYPE_FLEX_TICKET,
    ORDER_ITEM_TYPE_PRODUCT,
    ORDER_ITEM_TYPE_TICKET,
    OrderItem,
    OrderType, OrderValueStat, TicketsCountStat,
} from '@/services/OrderService/types';
import { PeriodicValueResponse } from '@bookingkit-private/api-v4/models/periodic-value-response';
import { MoneyAmount } from '@/services/AccountingService';
import { isValid } from 'date-fns';
import { getStatusIntent, mainInfoHero } from '@/components/SearchModal/searchResultMapper';
import { PeriodicCountResponse } from '@bookingkit-private/api-v4/models/periodic-count-response';

const mapOrderStatus = (apiOrderStatus?: OrderProductResponseStatusEnum): OrderStatusEnum => {
    switch (apiOrderStatus) {
    case OrderProductResponseStatusEnum.Accepted:
        return OrderStatusEnum.Accepted;
    case OrderProductResponseStatusEnum.Canceled:
        return OrderStatusEnum.Canceled;
    case OrderProductResponseStatusEnum.Open:
        return OrderStatusEnum.Open;
    case OrderProductResponseStatusEnum.Reserved:
        return OrderStatusEnum.Reserved;
    default:
        throw OrderServiceError.fromInvalidOrderStatus(apiOrderStatus);
    }
};

const validateTotal = (money?: MoneyResponse): money is MoneyResponse => {
    if (!money) {
        throw OrderServiceError.fromMissingProperty('total');
    }
    const { value, currency_code } = money;
    if (typeof value !== 'number') {
        throw OrderServiceError.fromInvalidPropertyType('total.value', 'number');
    }
    if (!currency_code) {
        throw OrderServiceError.fromMissingProperty('total.currency_code');
    }
    return true;
};
export const fromApiOrderToSearchResultOrder = (order :OrderSearchResultResponse) : SearchResult => {
    if (typeof order !== 'object' || !order) {
        throw OrderServiceError.fromMissingProperty('response');
    }

    const requiredProperties = ['code', 'id', 'date', 'total', 'status'];

    requiredProperties.forEach((property) => {
        if (!(property in order)) {
            throw OrderServiceError.fromMissingProperty(property);
        }
    });

    validateTotal(order.total);
    const { value, currency_code } = order.total as Required<MoneyResponse>;
    const status = mapOrderStatus(order.status);

    return {
        scope: ORDER,
        name: order.name ?? '',
        code: order.code as string,
        id: order.id as string,
        status,
        orderDate: order.date as string,
        headBadges: status
            ? [{ label: ORDER }, {
                label: status,
                type: getStatusIntent(status),
            }]
            : [{ label: ORDER }],
        mainInfoHero: mainInfoHero({ amount: value, currency: currency_code as CurrencyType }),

    };
};

const isOrderItemTicket = (arg: any): arg is OrderTicketResponse => arg?.order_item_type === 'TICKET';

const isOrderItemProduct = (arg: any): arg is OrderProductResponse => arg?.order_item_type === OrderSearchResultResponseOrderItemsInnerOrderItemTypeEnum.Product;
const isOrderItemFlexTicket = (arg: any): arg is OrderFlexibleTicketResponse => arg?.order_item_type === OrderFlexibleTicketResponseOrderItemTypeEnum.FlexibleTicket;

const fromApiOrderItemToOrderItem = (oderItem: OrderSearchResultResponseOrderItemsInner): OrderItem | false => {
    // This will be addressed in CORE2-809
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isOrderItemTicket(oderItem)) {
        const {
            contact, entry_code, availability_id, status,
        } = oderItem;
        return {
            type: ORDER_ITEM_TYPE_TICKET,
            availabilityId: availability_id as string,
            code: entry_code as string,
            contact: {
                fullName: contact?.full_name ?? 'none',
                email: contact?.email ?? 'none',
            },
            active: status === 'ACTIVE',
        };
    }

    if (isOrderItemProduct(oderItem)) {
        const {
            product, quantity,
        } = oderItem;
        return {
            type: ORDER_ITEM_TYPE_PRODUCT,
            name: product?.name as string,
            id: product?.id as string,
            quantity: quantity as number,
        };
    }

    if (isOrderItemFlexTicket(oderItem)) {
        return {
            type: ORDER_ITEM_TYPE_FLEX_TICKET,
            id: (oderItem as OrderFlexibleTicketResponse).id!,
            redeemCode: (oderItem as OrderFlexibleTicketResponse).redeem_code!,
            price: {
                value: (oderItem as OrderFlexibleTicketResponse).price?.value || 0,
                currency: (oderItem as OrderFlexibleTicketResponse).price?.currency_code as CurrencyType,
            },
        };
    }

    return false;
};

export const fromApiOrderToOrder = (order :OrderSearchResultResponse) : OrderType => {
    const requiredProperties = ['code', 'id', 'date', 'total', 'status', 'order_items'];

    requiredProperties.forEach((property) => {
        if (!(property in order)) {
            throw OrderServiceError.fromMissingProperty(property);
        }
    });
    const {
        id, name, order_items, code, status, date,
    } = order;

    validateTotal(order.total);
    const { value, currency_code } = order.total as Required<MoneyResponse>;
    return {
        orderItems: order_items?.map(fromApiOrderItemToOrderItem).filter((x) => !!x) as OrderItem[] ?? [],
        status: mapOrderStatus(status),
        name: name ?? '',
        code: code ?? '',
        id: id as string,
        date: date as string,
        amount: value,
        currency: currency_code as CurrencyType,
    };
};

const getTotalRevenue = (money:MoneyResponse):MoneyAmount => {
    const requiredProperties = ['value', 'currency_code'];
    requiredProperties.forEach((property) => {
        if (!(property in money)) {
            throw OrderServiceError.fromMissingProperty(property);
        }
    });
    if (typeof money.value !== 'number') {
        throw OrderServiceError.fromInvalidMoney('value');
    }
    if (!(money.currency_code as CurrencyType in currencySymbol)) {
        throw OrderServiceError.fromInvalidCurrency('currency');
    }
    return {
        value: money.value,
        currency: money.currency_code as CurrencyType,
    };
};

export const fromApiOrderValueToOrderValue = (orderValueResponse :PeriodicValueResponse) : OrderValueStat => {
    const requiredProperties = ['local_start_date', 'local_start_date', 'total_revenue'];
    requiredProperties.forEach((property) => {
        if (!(property in orderValueResponse)) {
            throw OrderServiceError.fromMissingProperty(property);
        }
    });
    if (!isValid(new Date(orderValueResponse.local_start_date as string))) {
        throw OrderServiceError.fromInvalidOrderDate('local_start_date');
    }
    if (!isValid(new Date(orderValueResponse.local_end_date as string))) {
        throw OrderServiceError.fromInvalidOrderDate('local_end_date');
    }
    return {
        startTime: orderValueResponse.local_start_date ? new Date(orderValueResponse.local_start_date) : undefined,
        endTime: orderValueResponse.local_end_date ? new Date(orderValueResponse.local_end_date) : undefined,
        totalRevenue: (orderValueResponse.total_revenue as MoneyAmount[])?.map(getTotalRevenue),
    };
};

export const fromApiTicketCountToTicketsCount = (orderValueResponse :PeriodicCountResponse) : TicketsCountStat => {
    const requiredProperties = ['local_start_date', 'local_end_date', 'tickets_count'];
    requiredProperties.forEach((property) => {
        if (!(property in orderValueResponse)) {
            throw OrderServiceError.fromMissingProperty(property);
        }
    });
    if (!isValid(new Date(orderValueResponse.local_start_date as string))) {
        throw OrderServiceError.fromInvalidOrderDate('local_start_date');
    }
    if (!isValid(new Date(orderValueResponse.local_end_date as string))) {
        throw OrderServiceError.fromInvalidOrderDate('local_end_date');
    }
    return {
        startTime: new Date(orderValueResponse.local_start_date!) as Date,
        endTime: new Date(orderValueResponse.local_end_date!) as Date,
        total: (orderValueResponse.tickets_count as number),
    };
};

export default { fromApiOrderToSearchResultOrder, fromApiTicketCountToTicketsCount };
