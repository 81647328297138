import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

/* eslint-disable */
import dateEnLocale from 'date-fns/locale/en-GB';
import dateFrLocale from 'date-fns/locale/fr';
import dateCsLocale from 'date-fns/locale/cs';
import dateDeLocale from 'date-fns/locale/de';
import dateEsLocale from 'date-fns/locale/es';
import dateItLocale from 'date-fns/locale/it';
import dateNlLocale from 'date-fns/locale/nl';
import dateFiLocale from 'date-fns/locale/fi';
import dateDaLocale from 'date-fns/locale/da';
import dateNoLocale from 'date-fns/locale/nb';
import dateSvLocale from 'date-fns/locale/sv';
import {twoDigitsToIsoLanguageCodes} from "@/constants/locales";
import {IUseTranslationService} from "@/composables/IUseTranslationService";
/* eslint-enable */

const applicationToDateFnsLocale = {
    [twoDigitsToIsoLanguageCodes.de]: dateDeLocale,
    [twoDigitsToIsoLanguageCodes.en]: dateEnLocale,
    [twoDigitsToIsoLanguageCodes.cs]: dateCsLocale,
    [twoDigitsToIsoLanguageCodes.fr]: dateFrLocale,
    [twoDigitsToIsoLanguageCodes.nl]: dateNlLocale,
    [twoDigitsToIsoLanguageCodes.fi]: dateFiLocale,
    [twoDigitsToIsoLanguageCodes.da]: dateDaLocale,
    [twoDigitsToIsoLanguageCodes.es]: dateEsLocale,
    [twoDigitsToIsoLanguageCodes.it]: dateItLocale,
    [twoDigitsToIsoLanguageCodes.no]: dateNoLocale,
    [twoDigitsToIsoLanguageCodes.sv]: dateSvLocale,
} as const;

type UseTranslationServiceConfig = {
  prefix?: string
}

export const getTwoLettersLocale = (localeToConvert: string) => Object.fromEntries(
    Object.entries(twoDigitsToIsoLanguageCodes)
        .map((a) => a.reverse()),
)[localeToConvert];

export const getDateLocaleKey = (
    localeToSearch: ApplicationLocale,
) => applicationToDateFnsLocale[localeToSearch as keyof typeof applicationToDateFnsLocale] ?? applicationToDateFnsLocale.en;

export const resolveTranslationPath = (
    key: string,
    prefix: string,
    translationFunction: (k: string, args?: unknown) => string,
    args?: unknown,
) => {
    const prefixToUse = !prefix.length || prefix.endsWith('.') ? prefix : `${prefix}.`;
    const keyToUse = !prefix.length && key.startsWith('.') ? key.slice(1) : key;
    return translationFunction(`${prefixToUse}${keyToUse}`, args);
};

export default function useTranslationService(options?:UseTranslationServiceConfig): IUseTranslationService {
    const { t, locale } = useI18n({
        inheritLocale: true,
        useScope: 'global',
    });

    const dateLocaleCode = getDateLocaleKey(locale.value as ApplicationLocale);
    const twoLettersLocaleCode = computed(() => getTwoLettersLocale(locale.value as string));

    const prefixedT = (key: string, args?: unknown) => resolveTranslationPath(
        key,
        options?.prefix ?? '',
        t,
        args,
    );
    return {
        t: prefixedT,
        locale,
        getTwoLettersLocale,
        twoLettersLocaleCode,
        dateLocaleCode,
    };
}
