import { ExperienceResponse } from '@bookingkit-private/api-v4/models/experience-response';
import { InventoryServiceError } from '@/services/InventoryService/Errors/InventoryServiceError';
import { Experience, Image } from '@/services/InventoryService/types';
import { ExperienceOptionInfo, ImageResponse } from '@bookingkit-private/api-v4';

export const mapExperienceOption = (option: ExperienceOptionInfo): {id: string, durationInMinutes: number } => {
    if (!option.id || typeof option.id !== 'string') {
        throw InventoryServiceError.fromInvalidProperty('option', 'string', option?.id);
    }

    if (typeof option.duration_in_minutes !== 'number') {
        throw InventoryServiceError.fromInvalidProperty('option duration_in_minutes', 'number', option?.duration_in_minutes);
    }

    return {
        id: option.id,
        durationInMinutes: option.duration_in_minutes,
    };
};

export const mapCoverImage = (coverImage: ImageResponse | undefined): Image | undefined => {
    if (coverImage) {
        if (typeof coverImage.id !== 'string') {
            throw InventoryServiceError.fromInvalidProperty('coverImage', 'string', coverImage.id);
        }
        if (!coverImage.sizes) {
            throw InventoryServiceError.fromInvalidProperty('coverImage', 'object', coverImage.sizes);
        }
        if (typeof coverImage.sizes?.small !== 'string') {
            throw InventoryServiceError.fromInvalidProperty('coverImage small url', 'string', coverImage.sizes?.small);
        }
        if (typeof coverImage.sizes?.medium !== 'string') {
            throw InventoryServiceError.fromInvalidProperty('coverImage medium url', 'string', coverImage.sizes?.medium);
        }
        if (typeof coverImage.sizes?.large !== 'string') {
            throw InventoryServiceError.fromInvalidProperty('coverImage large url', 'string', coverImage.sizes?.large);
        }
        if (typeof coverImage.sizes?.maximum !== 'string') {
            throw InventoryServiceError.fromInvalidProperty('coverImage maximum url', 'string', coverImage.sizes?.maximum);
        }
        return {
            id: coverImage.id,
            sizes: {
                small: coverImage.sizes.small,
                medium: coverImage.sizes.medium,
                large: coverImage.sizes.large,
                maximum: coverImage.sizes.maximum,
            },
        };
    }

    return undefined;
};

export const fromApiModel = (fromApiModelInput:ExperienceResponse):Experience => {
    const {
        id, title, options, cover_image,
    } = fromApiModelInput;

    if (typeof id !== 'string') {
        throw InventoryServiceError.fromInvalidProperty('id', 'string', id);
    }
    if (typeof title !== 'string') {
        throw InventoryServiceError.fromInvalidProperty('title', 'string', title);
    }
    if (!options) {
        throw InventoryServiceError.fromInvalidProperty('options', 'Object', options);
    }
    return {
        id,
        title,
        options: options.map((o) => mapExperienceOption(o)),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        coverImage: mapCoverImage(cover_image),
    };
};

export default { fromApiModel };
