import { LocationQuery } from 'vue-router';
import { IFRAME_URL } from '@/bootstrap/environment';

export const useManticoreNavigation = () => {
    const getQueryUrn = (query:LocationQuery | undefined) => {
        if (query) {
            return `?${ new URLSearchParams(query as Record<string, string>).toString() }`;
        }
        return '';
    };

    const getUrlWithQuery = (query:LocationQuery | undefined) => getQueryUrn(query);

    const generateMfeUrl = (path: string) => {
        if (path.indexOf('/') === 0) {
            return `${IFRAME_URL}${path}`;
        }
        return `${IFRAME_URL}/${path}`;
    };

    return ({
        getUrlWithQuery,
        generateMfeUrl,
    });
};

export default { useManticoreNavigation };

export type ManticoreComponentProps = {
    iFrameUrl: string,
    query?: LocationQuery,
    openNewTab?: boolean,
    routePage?: string,
    param?: LocationQuery
}
