import { PaymentMethod as ApiPaymentMethod } from '@bookingkit-private/api-v4';
import {
    PaymentMethodAdditionalFeesType,
    PaymentMethodExperienceFilterType, PaymentMethodSettingsType, PaymentMethodSettingTypeEnum,
    PaymentMethodType,
} from '@/modules/sell/pages/PaymentMethods/types';
import { AccountingServiceError } from '@/services/AccountingService/Errors/AccountingServiceError';
import { PaymentMethodSetting } from '@bookingkit-private/api-v4/models/payment-method-setting';

interface APIPaymentMethod extends ApiPaymentMethod{
    experience_filter?:PaymentMethodExperienceFilterType,
    additional_fees?:PaymentMethodAdditionalFeesType,
}

const mapSettingType = (type: string) : PaymentMethodSettingTypeEnum => {
    switch (type) {
    case 'text':
        return PaymentMethodSettingTypeEnum.TEXT;
    case 'email':
        return PaymentMethodSettingTypeEnum.EMAIL;
    case 'date':
        return PaymentMethodSettingTypeEnum.DATE;
    case 'boolean':
        return PaymentMethodSettingTypeEnum.BOOLEAN;
    default:
        throw AccountingServiceError.fromMissingProperty('Payment method setting type');
    }
};

const mapSettingValue = (type: PaymentMethodSettingTypeEnum, value: string) : string | boolean => {
    if (type === PaymentMethodSettingTypeEnum.BOOLEAN) {
        return !!value;
    }
    return value;
};

const mapSetting = (settings: Array<PaymentMethodSetting>) :
        Array<PaymentMethodSettingsType> => settings.map((setting:PaymentMethodSetting) => {
    if (typeof setting.type !== 'string') {
        throw AccountingServiceError.fromMissingProperty('Payment method setting type');
    }
    if (typeof setting.value !== 'string') {
        throw AccountingServiceError.fromMissingProperty('Payment method setting value');
    }
    const type = mapSettingType(setting.type);
    return {
        value: mapSettingValue(type, setting.value),
        type,
        fieldName: setting.field_name,
        required: setting.required,
    };
});

export const fromApiPaymentMethodToPaymentMethod = (pm?: APIPaymentMethod): PaymentMethodType => {
    if (!pm) {
        throw AccountingServiceError.fromMissingProperty('Payment method');
    }
    return {
        id: pm.id as PaymentMethodType['id'],
        active: pm.active !== undefined ? pm.active : false,
        settings: pm.settings ? mapSetting(pm.settings as Array<PaymentMethodSetting>) : undefined,
        experience_filter: pm.experience_filter ? pm.experience_filter : undefined,
        additional_fees: pm.additional_fees ? pm.additional_fees : undefined,
    };
};

export default { fromApiPaymentMethodToPaymentMethod };
