import { ref, computed, defineAsyncComponent } from 'vue';
import {
    getShortcutsActiveStateFromStorage, addShortcutsActiveStateToStorage,
    addShortcutsToastStateToStorage,
    getShortcutsToastStateToStorage,
} from '@/services/localStorage/shortcuts';
import {
    addCalendarGroupingSettingsToStorage,
    addCalendarImageSettingsToStorage,
    getCalendarInactiveExpSettingsFromStorage,
    getCalendarGroupingSettingFromStorage,
    getCalendarImageSettingFromStorage, addCalendarInactiveExpSettingsToStorage,
} from '@/services/localStorage/calendarSettings';
// eslint-disable-next-line no-shadow
export const enum ModalViewsTypes {
  SupplierSwitchModalView = 'SupplierSwitchModalView',
  SupplierOnBoardingModalView = 'SupplierOnBoardingModalView',
  PayoutModalView= 'PayoutModalView',
  SearchModalView= 'SearchModalView',
  PowerShortcutsModalView= 'PowerShortcutsModalView',
  ExperienceListModalView= 'ExperienceListModalView',
  CreateNewWidgetModalView= 'CreateNewWidgetModalView',
}

// eslint-disable-next-line no-shadow
export const enum ModalAnchorPoints {
  Top = 'top',
  Center = 'center',
}

export type ModalComponent = {
    name: string,
    loader: () => ReturnType<typeof defineAsyncComponent>|undefined,
}

export type ModalConfiguration = {
    component: ModalComponent|undefined,
    anchorPoint?: ModalAnchorPoints,
    properties?: Record<string, unknown>,
    on?: Record<string, unknown>
    dismissible?: boolean,
    wrapped?: boolean,
    maxWidth?: number|null,
    title?: string|null,
}

const defaultModalConfiguration: ModalConfiguration = {
    component: undefined,
    anchorPoint: ModalAnchorPoints.Center,
    properties: {},
    on: {},
    wrapped: true,
    dismissible: true,
    maxWidth: null,
    title: null,
};

interface applicationStateInterface {
  isDesktopLayout: boolean,
  experienceListModalIsActive: boolean,
  supplierSwitchModalIsActive: boolean,
  supplierOnBoardingIsActive: boolean,
  searchModalIsActive: boolean,
  powerShortcutsModalIsActive: boolean,
  CreateNewWidgetModalViewIsActive:boolean,
  enablePowerShortcuts: boolean,
  powerShortcutsActionToast: boolean,
  powerShortcutsInfoToast: { active : boolean, message: string | undefined },
  payoutModalIsActive: boolean,
  modalProperties: Record<string, unknown>
  modalDisableClosing: boolean,
  modalConfig: ModalConfiguration,
  modalIsOpen: boolean,
  enableCalendarImages: boolean,
  showCalendarInActiveExp: 1|0,
  calendarGroupingSetting: number,
}

const applicationState = ref<applicationStateInterface>({
    isDesktopLayout: true,
    experienceListModalIsActive: false,
    supplierSwitchModalIsActive: false,
    supplierOnBoardingIsActive: false,
    searchModalIsActive: false,
    powerShortcutsModalIsActive: false,
    CreateNewWidgetModalViewIsActive: false,
    enablePowerShortcuts: true,
    powerShortcutsActionToast: false,
    powerShortcutsInfoToast: { active: false, message: undefined },
    payoutModalIsActive: false,
    modalProperties: {},
    modalDisableClosing: false,
    modalConfig: defaultModalConfiguration,
    modalIsOpen: false,
    enableCalendarImages: getCalendarImageSettingFromStorage(),
    showCalendarInActiveExp: getCalendarInactiveExpSettingsFromStorage(),
    calendarGroupingSetting: getCalendarGroupingSettingFromStorage(),
});

export const getIsDesktopLayout = () => applicationState.value.isDesktopLayout;

export const setIsDesktopLayout = (isDesktopLayout:boolean) => {
    applicationState.value.isDesktopLayout = isDesktopLayout;
};

const setModalProperties = (props: Record<string, unknown>) => {
    applicationState.value.modalProperties = props;
};

export const getModalProperties = ():
  Record<string, unknown> => applicationState.value.modalProperties;

const getModalDisableClosing = () : boolean => (
    applicationState.value.modalDisableClosing
);

const setModalDisableClosing = (value: boolean) : void => {
    applicationState.value.modalDisableClosing = value;
};

const deactivateSupplierSwitchModal = () => {
    applicationState.value.supplierSwitchModalIsActive = false;
    setModalProperties({});
};

const activateSupplierSwitchModal = () => {
    applicationState.value.supplierSwitchModalIsActive = true;
};

const deactivateSupplierOnBoardingModal = () => {
    applicationState.value.supplierOnBoardingIsActive = false;
};

const activateSupplierOnBoardingModal = () => {
    applicationState.value.supplierOnBoardingIsActive = true;
};

const activateExperienceListModal = () => {
    applicationState.value.experienceListModalIsActive = true;
};
const deactivateExperienceListModal = () => {
    applicationState.value.experienceListModalIsActive = true;
    setModalProperties({});
};
const deactivatePayoutModal = () => {
    applicationState.value.payoutModalIsActive = false;
    setModalProperties({});
};

const activatePayoutModal = (modalProps: Record<string, unknown>) => {
    applicationState.value.payoutModalIsActive = true;
    setModalProperties(modalProps);
    setModalDisableClosing(false);
};

const activateSearchModal = () => {
    applicationState.value.searchModalIsActive = true;
};
const deactivateSearchModal = () => {
    applicationState.value.searchModalIsActive = false;
    setModalProperties({});
};

const activateCreateNewWidgetModal = () => {
    applicationState.value.CreateNewWidgetModalViewIsActive = true;
};
const deactivateCreateNewWidgetModal = () => {
    applicationState.value.CreateNewWidgetModalViewIsActive = false;
    setModalProperties({});
};

const activatePowerShortcutsModal = () => {
    applicationState.value.powerShortcutsModalIsActive = true;
};
const deactivatePowerShortcutsModal = () => {
    applicationState.value.powerShortcutsModalIsActive = false;
    setModalProperties({});
};

const enablePowershortcuts = (value:boolean) => {
    addShortcutsActiveStateToStorage(value);
    applicationState.value.enablePowerShortcuts = value;
};

const isToastShortcutMessageAlreadyShown = () => getShortcutsToastStateToStorage();

const isPowerShortcutsEnabled = () => getShortcutsActiveStateFromStorage();

const isSearchModalActive = () => applicationState.value.searchModalIsActive;

const isExperienceListModalActive = () => applicationState.value.experienceListModalIsActive;

const isSupplierSwitchModalActive = () => applicationState.value.supplierSwitchModalIsActive;

const isShortcutsInfoToastActive = () => applicationState.value.powerShortcutsInfoToast;

const isShortcutInfoActionToastActive = () => applicationState.value.powerShortcutsActionToast;

const activateShortcutActionToast = () => {
    applicationState.value.powerShortcutsActionToast = true;
};

const deActivateShortcutActionToast = () => {
    applicationState.value.powerShortcutsActionToast = false;
};

const activateShortcutInfoToast = (value :{ active: boolean, message:string}) => {
    applicationState.value.powerShortcutsInfoToast = value;
    addShortcutsToastStateToStorage(true);
};

const deActivateShortcutInfoToast = () => {
    applicationState.value.powerShortcutsInfoToast = { active: false, message: undefined };
};

const closeModal = () => {
    applicationState.value.supplierSwitchModalIsActive = false;
    applicationState.value.searchModalIsActive = false;
    applicationState.value.powerShortcutsModalIsActive = false;
    applicationState.value.CreateNewWidgetModalViewIsActive = false;
    applicationState.value.payoutModalIsActive = false;
    applicationState.value.experienceListModalIsActive = false;
    applicationState.value.supplierOnBoardingIsActive = false;
    applicationState.value.modalConfig = defaultModalConfiguration;
    applicationState.value.modalIsOpen = false;
    deActivateShortcutInfoToast();
    deActivateShortcutActionToast();
};

const openModal = (modalConfig: ModalConfiguration) => {
    applicationState.value.modalConfig = {
        ...defaultModalConfiguration,
        ...modalConfig,
    };
    applicationState.value.modalIsOpen = true;
};

const getActiveModalView = () => {
    if (applicationState.value.experienceListModalIsActive) {
        return ModalViewsTypes.ExperienceListModalView;
    }
    if (applicationState.value.supplierSwitchModalIsActive) {
        return ModalViewsTypes.SupplierSwitchModalView;
    }
    if (applicationState.value.supplierOnBoardingIsActive) {
        return ModalViewsTypes.SupplierOnBoardingModalView;
    }
    if (applicationState.value.payoutModalIsActive) {
        return ModalViewsTypes.PayoutModalView;
    }
    if (applicationState.value.searchModalIsActive) {
        return ModalViewsTypes.SearchModalView;
    }
    if (applicationState.value.powerShortcutsModalIsActive) {
        return ModalViewsTypes.PowerShortcutsModalView;
    }
    if (applicationState.value.CreateNewWidgetModalViewIsActive) {
        return ModalViewsTypes.CreateNewWidgetModalView;
    }
    return false;
};

const getModalAnchorPoint = () : ModalAnchorPoints => {
    const activeModalView = getActiveModalView();
    return (
        (activeModalView === ModalViewsTypes.PayoutModalView
            || activeModalView === ModalViewsTypes.PowerShortcutsModalView
            || activeModalView === ModalViewsTypes.CreateNewWidgetModalView)
            ? ModalAnchorPoints.Center : ModalAnchorPoints.Top
    );
};

const getSettingsForCalendarGrouping = computed(() => applicationState.value.calendarGroupingSetting);
const isSettingsForCalendarImageEnabled = () => applicationState.value.enableCalendarImages;

const isSettingsForCalendarInactiveExpEnabled = () => applicationState.value.showCalendarInActiveExp;

const setSettingsForCalendarInactiveExp = (value:1|0) => {
    addCalendarInactiveExpSettingsToStorage(value);
    applicationState.value.showCalendarInActiveExp = value;
};
const setSettingsForCalendarImageEnabled = (value:boolean) => {
    addCalendarImageSettingsToStorage(value);
    applicationState.value.enableCalendarImages = value;
};

const setSettingsForCalendarGrouping = (value:60|30|15) => {
    addCalendarGroupingSettingsToStorage(value);
    applicationState.value.calendarGroupingSetting = value;
};

export default function useApplicationState() {
    return {
        applicationState: computed(() => applicationState.value),
        activateSupplierSwitchModal,
        deactivateSupplierSwitchModal,
        activateSupplierOnBoardingModal,
        deactivateSupplierOnBoardingModal,
        activatePayoutModal,
        deactivatePayoutModal,
        activateSearchModal,
        deactivateSearchModal,
        isSearchModalActive,
        isSupplierSwitchModalActive,
        isExperienceListModalActive,
        activatePowerShortcutsModal,
        deactivatePowerShortcutsModal,
        enablePowershortcuts,
        isPowerShortcutsEnabled,
        isToastShortcutMessageAlreadyShown,
        isShortcutsInfoToastActive,
        isShortcutInfoActionToastActive,
        activateShortcutInfoToast,
        activateExperienceListModal,
        deactivateExperienceListModal,
        deActivateShortcutInfoToast,
        activateShortcutActionToast,
        deActivateShortcutActionToast,
        openModal,
        closeModal,
        getModalProperties,
        setModalProperties,
        getActiveModalView: computed(getActiveModalView),
        getModalAnchorPoint: computed(getModalAnchorPoint),
        getModalDisableClosing: computed(getModalDisableClosing),
        getModalConfiguration: computed(() => applicationState.value.modalConfig),
        setModalDisableClosing,
        modalIsOpen: computed(() => applicationState.value.modalIsOpen),
        isSettingsForCalendarImageEnabled,
        setSettingsForCalendarImageEnabled,
        setSettingsForCalendarGrouping,
        getSettingsForCalendarGrouping,
        isSettingsForCalendarInactiveExpEnabled,
        setSettingsForCalendarInactiveExp,
        activateCreateNewWidgetModal,
        deactivateCreateNewWidgetModal,
    };
}
