import { injectable, inject } from 'inversify';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { AUTHENTICATION_SERVICE, LOGGER_SERVICE } from '@/bootstrap/ServiceProviders';
import { Configuration, DefaultApiFactory } from '@bookingkit-private/api-v4';
import { BOOKINGKIT_API_V4_BASE_URL } from '@/bootstrap/environment';
import axios from 'axios';
import { onFullFilled, createOnRejected } from '@/Factories/BookingkitApi/Interceptors';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';

@injectable()
export class BookingKitApiFactory implements BookingKitApiFactoryInterface {
    private readonly authProviderService: AuthenticationServiceInterface;

    private readonly logger: LoggerServiceInterface;

    constructor(
      @inject(AUTHENTICATION_SERVICE) KeycloakService: AuthenticationServiceInterface,
      @inject(LOGGER_SERVICE) logger: LoggerServiceInterface,
    ) {
        this.logger = logger;
        this.authProviderService = KeycloakService;
        this.getConnection = this.getConnection.bind(this);
    }

    public getConnection(): ReturnType<typeof DefaultApiFactory> {
        const { authProviderService } = this;
        const axiosInstance = axios.create({});

        axiosInstance.interceptors.response.use(onFullFilled, createOnRejected(this.logger).bind(this));
        return DefaultApiFactory(
            new Configuration({
                basePath: BOOKINGKIT_API_V4_BASE_URL,
                accessToken: () => authProviderService.getToken(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            }), undefined, axiosInstance,
        );
    }
}

export default BookingKitApiFactory;
