import { Transaction } from '@bookingkit-private/api-v4';
import { WalletTransaction } from '@/services/AccountingService/types';
import { AccountingServiceError }
    from '@/services/AccountingService/Errors/AccountingServiceError';

export const mapTransactionToWalletTransaction = (data: Transaction): WalletTransaction => {
    const validators = {
        id: (id: unknown) => typeof id === 'string',
        transaction_type: () => true,
        amount: () => true,
        creation_date: () => true,
        execution_date: () => true,
    } as Record<keyof Transaction, (args?: unknown) => boolean>;

    Object.keys(validators).forEach((propertyToValidate) => {
        const transactionProperty = propertyToValidate as unknown as keyof Transaction;
        // THIS IS RUNNING THE VALIDATOR HERE ON TOP
        if (!validators[transactionProperty](data[transactionProperty])) {
            throw AccountingServiceError.fromMissingProperty(propertyToValidate);
        }
    });

    return {
        amount: data.amount as WalletTransaction['amount'],
        type: data.transaction_type as WalletTransaction['type'],
        date: data.execution_date,
        flow: data.flow as WalletTransaction['flow'],
        order: data.transaction_meta_data?.related_order,
    };
};

export default {
    mapTransactionToWalletTransaction,
};
