import { SupplierScopeType, SupplierType } from '@/models/User';
import { HasRequiredScopesSpecification } from '@/services/AuthorisationService';

export class AuthorisationService {
    static supplierHasAllScopes(supplier: SupplierType, requiredScopes: SupplierScopeType[]): boolean {
        return requiredScopes.every((scope) => {
            const specification = new HasRequiredScopesSpecification(scope);
            return specification.isSatisfiedBy(supplier);
        });
    }

    static supplierHasAtLeasOneScope(supplier: SupplierType, needles: SupplierScopeType[]): boolean {
        return needles.some((scope) => {
            const specification = new HasRequiredScopesSpecification(scope);
            return specification.isSatisfiedBy(supplier);
        });
    }
}

export default AuthorisationService;
