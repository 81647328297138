import { SupplierType, UserType } from '@/models/User';
import { DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH, INTERCOM_APP_ID } from '@/bootstrap/environment';
import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { LOGGER_SERVICE } from '@/bootstrap/ServiceProviders';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import IntercomSettings = Intercom_.IntercomSettings;
import IntercomCommandSignature = Intercom_.IntercomCommandSignature;

const APP_ID = INTERCOM_APP_ID;
const INTERCOM_BOOT_ERROR = 'Intercom not booted';
const loggerService = applicationContainer.get<LoggerServiceInterface>(LOGGER_SERVICE);

declare global {
    interface Window {
        Intercom: any;
        intercomSettings?: IntercomSettings;
    }
}

export function loadIntercom() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    const w = window; const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings); } else { const d = document; const i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; const l = function () { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${ APP_ID}`; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } }
}

export function bootIntercom(user :UserType, supplier: SupplierType, twoLettersLocaleCode = 'en') {
    try {
        if (window.Intercom) {
            window.intercomSettings = {
                hide_default_launcher: window?.innerWidth < DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH,
                horizontal_padding: 30,
                vertical_padding: 30,
                background_color: '#005EEB',
                language_override: twoLettersLocaleCode,
                company: {
                    name: supplier.name,
                    id: supplier.id,
                },
                email: user.email,
                user_id: user.id,
                vendor_id: supplier.id,
                name: user.name,
            };
            window.Intercom('boot', {
                app_id: APP_ID,
                email: user.email,
                user_id: user.id,
                name: user.name,
            });
        } else {
            throw new Error(INTERCOM_BOOT_ERROR);
        }
    } catch (e) {
        loggerService.captureException(e);
    }
}

function showNewMessage() {
    if (window.Intercom) {
        window.Intercom('showNewMessage');
    } else {
        throw new Error(INTERCOM_BOOT_ERROR);
    }
}

export function openIntercomNewMessage() {
    try {
        showNewMessage();
    } catch (e) {
        loggerService.captureException(e);
    }
}

export function openIntercomNewsFeed() {
    try {
        Intercom('showNews' as keyof IntercomCommandSignature);
    } catch (e) {
        loggerService.captureException(e);
    }
}

export function trackIntercomEvent(event :string) {
    try {
        if (!event) {
            throw new Error('Empty Intercom event name!');
        }
        Intercom('trackEvent' as keyof IntercomCommandSignature, event);
    } catch (e) {
        loggerService.captureException(e);
    }
}

export function updateIntercom(data:Record<string, any> = {}) {
    try {
        window.Intercom('update', { last_request_at: new Date().getTime() / 1000, ...data });
    } catch (e) {
        loggerService.captureException(e);
    }
}

export default {
    loadIntercom, bootIntercom, openIntercomNewMessage, updateIntercom,
};
