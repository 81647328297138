<template>
  <div>
    <router-view />
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
  .settings-page-route {
    display: grid;
    grid-gap: var(--s4);
    grid-template-columns: [full-start] 0 [main-start] repeat(4, 1fr) [main-end] 0 [full-end];
    width: 100%;
  }

  @media screen and (min-width: 769px) {
    .settings-page-route {
      grid-gap: var(--s6);
      grid-template-columns: [full-start] 0 [main-start] repeat(8, 1fr) [main-end] 0 [full-end];
    }
  }

  @media screen and (min-width: 1060px) {
    .settings-page-route {
      grid-template-columns: [full-start] minmax(calc(calc(100% - 1184px) / 2), 1fr) [main-start] repeat(12, 1fr) [main-end] minmax(calc(calc(100% - 1184px) / 2), 1fr) [full-end];
    }
  }
</style>
