import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { UseSupplierInterface } from '@/state/supplier';
import { fromApiModulesToArrayModules } from '@/services/ModuleService/Reducers/moduleReducers';
import { ModuleServiceError } from '@/services/ModuleService/Errors/ModuleServiceError';
import { ModuleArrayMapper } from '@/services/ModuleService/types';

@injectable()
export class ModuleService {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    private supplierId: string;

    constructor(
        @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
        @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
    ) {
        const { supplier } = useSupplierState();
        if (!supplier.value?.id) {
            throw ModuleServiceError.fromMissingSupplier();
        }
        this.supplierId = supplier.value.id;
        this.connection = factory.getConnection();
        this.listModules = this.listModules.bind(this);
    }

    async listModules(pageSize ?:number): Promise<ModuleArrayMapper[]> {
        const { supplierId } = this;
        const { data } = await this.connection.listModules(supplierId, pageSize);
        const moduleList = data.data;
        if (moduleList) {
            return moduleList.map(fromApiModulesToArrayModules);
        }
        return [];
    }
}

export default { ModuleService };
