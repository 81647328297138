import { ShortcutError } from '@/services/ShortcutService/Exceptions/ShortcutError';
import { Shortcut } from '@/services/ShortcutService/constants';

let currentShortcut: string[] = [];

let selfClearanceTimeout: ReturnType<typeof setTimeout>|undefined;

const REGISTERED_SHORTCUTS: { [key: string]: Shortcut | undefined } = {};

const generateStringKey = (keys: string[]) => [...keys].map((s) => s.toLowerCase()).sort().join('-');

const addShortcut = (s: Shortcut) => {
    const identifier = generateStringKey(s.keys);
    if (REGISTERED_SHORTCUTS[identifier] !== undefined) {
        throw ShortcutError.forShortcutAlreadyRegistered(identifier);
    }
    REGISTERED_SHORTCUTS[identifier] = s;
};

const clearCurrentShortcut = () => {
    currentShortcut = [];
};

const resetSelfClearanceTimeout = () => {
    if (selfClearanceTimeout) {
        clearTimeout(selfClearanceTimeout);
    }
    selfClearanceTimeout = setTimeout(clearCurrentShortcut, 2000);
};

const deleteShortcut = (s: Shortcut) => {
    const identifier = generateStringKey(s.keys);
    if (REGISTERED_SHORTCUTS[identifier] === undefined) {
        throw ShortcutError.forNotFoundShortcut(identifier);
    }
    REGISTERED_SHORTCUTS[identifier] = undefined;
};

const findMatchingShortcut = (searched: string[]): false | Shortcut => {
    const identifier = generateStringKey(searched);

    return REGISTERED_SHORTCUTS[identifier] ?? false;
};

const handleKeyDownEvent = (e: KeyboardEvent):void => {
    if (e.key === undefined) { return; }
    const key = e.key.toLowerCase();
    if (!currentShortcut.includes(key)) {
        currentShortcut.push(key);
    }
    const trigger = findMatchingShortcut(currentShortcut);
    if (trigger) {
        trigger.callback(e);
        currentShortcut = currentShortcut.filter((x) => (x === 'meta' || x === 'control'));
    }
};
const handleKeyUpEvent = (e: KeyboardEvent):void => {
    if (e.key === undefined) { return; }
    const key = e.key.toLowerCase();

    if (currentShortcut.includes(key)) {
        currentShortcut = currentShortcut.filter((a) => a !== key);
    }
};

export const handleKeyboardEvent = (e: KeyboardEvent):void => {
    resetSelfClearanceTimeout();
    if (e.type === 'keyup') {
        handleKeyUpEvent(e);
    }
    if (e.type === 'keydown') {
        handleKeyDownEvent(e);
    }
};

export const registerShortcut = (shortcut: Shortcut):void => {
    addShortcut(shortcut);
};
export const deregisterShortcut = (shortcut: Shortcut):void => {
    deleteShortcut(shortcut);
};

export default {
    registerShortcut,
    deregisterShortcut,
    handleKeyboardEvent,
};
