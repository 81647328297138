export class RefreshTokenError extends Error {
    constructor(...params: any[]) {
        super(...params);
        this.message = 'Could not refresh token';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RefreshTokenError);
        }

        this.name = 'RefreshTokenError';
    }
}

export default RefreshTokenError;
