import { ModuleArrayMapper } from '@/services/ModuleService/types';
import { ModuleResponse } from '@bookingkit-private/api-v4/models/module-response';
import { MODULES, ModuleType } from '@/models/Modules';

const getModuleAsArray = (id:string | undefined) :string[] => {
    const moduleArray = [];
    if (id && id in MODULES) {
        moduleArray.push(id as ModuleType);
    }
    return moduleArray;
};

export const fromApiModulesToArrayModules = (module :ModuleResponse) : ModuleArrayMapper => ({
    id: getModuleAsArray(module.id),
    status: module.status,
    activationDate: module.activationDate,
});

export default { fromApiModulesToArrayModules };
