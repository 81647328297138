import {
    init as SentryInit,
    captureException as captureExceptionOnSentry,
    setUser, browserTracingIntegration,
    globalHandlersIntegration,
} from '@sentry/vue';
import { ScopeContext } from '@sentry/types';
import { inject, injectable } from 'inversify';

import { BindToApplicationConfig, LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { PRODUCTION } from '@/constants';
import { NODE_ENV, SENTRY_TRACES_SAMPLE_RATE, SENTRY_TRACING_ORIGINS } from '@/bootstrap/environment';
import { SUPPLIER_STATE_SERVICE, USER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { UseSupplierInterface } from '@/state/supplier';
import { UseUserInterface } from '@/state/user';

@injectable()
export class SentryService implements LoggerServiceInterface {
  private FALLBACK_ENVIRONMENT = NODE_ENV || PRODUCTION;

    private readonly useSupplierState: () => UseSupplierInterface;

    private readonly useUserState: () => UseUserInterface;

  private tracesSampleRate = SENTRY_TRACES_SAMPLE_RATE
      ? parseFloat(SENTRY_TRACES_SAMPLE_RATE) : 0;

  private tracingOrigins = SENTRY_TRACING_ORIGINS?.split(',') || [/^\//];

  constructor(
      @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
      @inject(USER_STATE_SERVICE) useUser: () => UseUserInterface,
  ) {
      this.useSupplierState = useSupplierState;
      this.useUserState = useUser;
  }

  bindToApplication = (options: BindToApplicationConfig): false | void => {
      const {
          application,
          router,
          environment,
          dist,
          dsn,
          release,
          tunnel,
      } = options;

      const { tracesSampleRate, FALLBACK_ENVIRONMENT } = this;
      const integrations = router ? [
          browserTracingIntegration({
              router,
              traceXHR: false,
              traceFetch: false,
              routeLabel: 'path',
          }),
          globalHandlersIntegration({
              onerror: false,
              onunhandledrejection: false,
          }),
      ] : [];

      return SentryInit({
          debug: false,
          app: application,
          tunnel,
          dsn,
          tracesSampleRate,
          integrations,
          environment: environment ?? FALLBACK_ENVIRONMENT,
          dist,
          release,
      });
  }

    setUser = (userData: {id?:string}) => {
        setUser(userData);
    }

  captureException = (
      exception: unknown,
      context?: Partial<ScopeContext>,
  ): string => {
      try {
          const { supplier } = this.useSupplierState();
          const { user } = this.useUserState();
          const extra = {
              supplierId: supplier.value?.id ?? 'not-set',
              userId: user.value?.id ?? 'not-set',
          };

          if (context === undefined) {
              const sentryContext = {
                  extra,
              };
              return captureExceptionOnSentry(exception, sentryContext);
          }
          const sentryContext = {
              ...context,
              extra: { ...context.extra, ...extra },
          };
          return captureExceptionOnSentry(exception, sentryContext);
      } catch (e) {
          console.log('BOOKINGKIT: A critical error just happened, sentry could not store it', e);
          return 'Contact service';
      }
  }
}

export default SentryService;
