const TIME_FOR_FIRST_INTERACTION = 200;
type ListenerTimeout = number | ReturnType<typeof setTimeout>

let listenForClickOutsideEvent:ListenerTimeout = 0;

export const clickOutside = {
    beforeMount: (el: HTMLElement & { clickOutsideEvent: (event: any) => void }, binding: {value: () => void}) => {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event) => {
            // Check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // if its, call the method from the attribute provided
                binding.value();
            }
        };
        // mount after DELAY from mounted (useful to avoid closing on double-click )
        listenForClickOutsideEvent = setTimeout(() => {
            document.addEventListener('click', el.clickOutsideEvent);
        }, TIME_FOR_FIRST_INTERACTION);
    },
    unmounted: (el: HTMLElement & { clickOutsideEvent: () => void }) => {
        clearTimeout(listenForClickOutsideEvent);
        document.removeEventListener('click', el.clickOutsideEvent);
    },
};
export default clickOutside;
