import { AxiosError } from 'axios';
import { ApiV4BaseError } from '@/Factories/BookingkitApi/Errors/ApiV4BaseError';

export class ApiV4ServerError extends ApiV4BaseError {
    constructor(message: string, code?: string, details?: Record<string, any>) {
        super(message, code, details);
        this.message = `${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiV4ServerError);
        }

        this.name = 'ApiV4ServerError';
    }

    static fromAxiosError(originalError: AxiosError<{error_code: string, error_message: string, error_details: Record<string, any>}>) {
        return new ApiV4ServerError(originalError.response?.data?.error_message || `An error occurred: ${originalError.message}`,
            originalError.response?.data?.error_code,
            originalError.response?.data?.error_details);
    }
}

export default ApiV4ServerError;
