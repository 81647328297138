import { ORDER_ROUTES_NAMES } from './routeNames';

export const ORDER = 'orders';
export const VOUCHER = 'vouchers';
export const COUPON = 'coupons';
export const FLEXIBLE_TICKET = 'flexible-tickets';
export const FEATURE = 'features';

export const ROUTE_MAPPING = {
    [ORDER]: ORDER_ROUTES_NAMES.ORDERS,
    [VOUCHER]: ORDER_ROUTES_NAMES.VOUCHERS_DETAILS,
    [COUPON]: ORDER_ROUTES_NAMES.COUPONS_DETAILS,
    [FLEXIBLE_TICKET]: ORDER_ROUTES_NAMES.FLEXIBLE_TICKETS_DETAILS,
    [FEATURE]: 'features',
};

export const enum SearchScopeEnum {
    All ='All',
    Orders='Orders',
    Vouchers='Vouchers',
    Coupons ='Coupons',
    FlexibleTickets = 'FlexibleTickets',
    Features ='Features',
}

export const OrderStatusEnum = {
    Accepted: 'ACCEPTED',
    Archived: 'ARCHIVED',
    Canceled: 'CANCELED',
    Declined: 'DECLINED',
    Expired: 'EXPIRED',
    Failure: 'FAILURE',
    Pending: 'PENDING',
    Open: 'OPEN',
    Reserved: 'RESERVED',
}as const;

export const VoucherStatusEnum = {
    VALID: 'VALID',
    INVALID: 'INVALID',
}as const;

export type OrderStatusEnum = typeof OrderStatusEnum[keyof typeof OrderStatusEnum];
export type VoucherStatusEnum = typeof VoucherStatusEnum[keyof typeof VoucherStatusEnum];
