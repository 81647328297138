import { useUserSupplier } from '@/composables/useSupplier/useUserSupplier';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { SupplierAlreadyInUseError } from '@/services/SupplierService/Errors/SupplierAlreadyInUseError';
import { IRouterService } from '@/router/IRouterService';

const SET_SUPPLIER_QUERY_PARAM = 'setVendor';
const MANTICORE_ROUTE = 'fromLegacy';

export type SetSupplierFromUrlInput = {
    useUserSupplier: ReturnType<typeof useUserSupplier>,
    loggerService: LoggerServiceInterface
}
export const setSupplierFromUrl = async (setSupplierFromUrlInput: SetSupplierFromUrlInput) => {
    const searchParams = new URLSearchParams((window.location.search));
    if (searchParams.get(SET_SUPPLIER_QUERY_PARAM) && searchParams.get(SET_SUPPLIER_QUERY_PARAM) !== null) {
        const { setVendorWithID } = setSupplierFromUrlInput.useUserSupplier;
        try {
            await setVendorWithID(searchParams.get(SET_SUPPLIER_QUERY_PARAM) as string);
            searchParams.delete(SET_SUPPLIER_QUERY_PARAM);
            window.location.search = searchParams.toString();
        } catch (e) {
            if (!(e instanceof SupplierAlreadyInUseError)) {
                setSupplierFromUrlInput.loggerService.captureException(e);
            }
        }
    }
};

export const setRouteFromLegacyApp = ({ routerService }: {routerService: IRouterService}):void => {
    const searchParams = new URLSearchParams((window.location.search));
    if (searchParams.get(MANTICORE_ROUTE) && searchParams.get(MANTICORE_ROUTE) !== null) {
        console.log(decodeURI(searchParams.get(MANTICORE_ROUTE) as string));
        routerService.navigateToLegacyRoute(decodeURI(searchParams.get(MANTICORE_ROUTE) as string));
    }
};
export default {
    setSupplierFromUrl,
};
