import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import {
    PaymentProvider, PaymentProviderSwitchRequest,
    WalletBalanceType,
    WalletTransaction,
} from '@/services/AccountingService/types';
import {
    mapTransactionToWalletTransaction,
    fromApiPaymentMethodToPaymentMethod,
    fromApiWalletToApplicationWallet,
    fromApiPaymentProviderToApplicationPaymentProvider,
} from '@/services/AccountingService/Reducers';

import { AccountingServiceError }
    from '@/services/AccountingService/Errors/AccountingServiceError';
import { UseSupplierInterface } from '@/state/supplier';
import { PaymentMethodType } from '@/modules/sell/pages/PaymentMethods/types';
import {
    SetPaymentOptionsRequest,
} from '@bookingkit-private/api-v4/dist/models';
import { SetActivationIntentTypeEnum } from '@bookingkit-private/api-v4/models/set-activation-intent';
import { AccountingServiceWalletError }
    from '@/services/AccountingService/Errors/AccountingServiceWalletError';
import {
    ListPayoutDestinationResponse,
} from '@bookingkit-private/api-v4/models/list-payout-destination-response';
import { Money } from '@bookingkit-private/api-v4';

@injectable()
export class AccountingService {
  private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

  private supplierId: string;

  constructor(
    @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
    @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
  ) {
      const { supplier } = useSupplierState();
      if (!supplier.value?.id) {
          throw AccountingServiceError.fromMissingSupplier();
      }
      this.supplierId = supplier.value.id;
      this.connection = factory.getConnection();
      this.getPaymentProviders = this.getPaymentProviders.bind(this);
  }

  async createPayoutDestination(walletId: string, country: string, currency: string, accountNumber: string):
        Promise<PaginatedResponse<WalletTransaction>> {
      const { supplierId } = this;

      await this.connection
          .payoutDestinationCreate(supplierId, walletId, {
              country,
              currency,
              account_number: accountNumber,
              type: 'BANK_ACCOUNT',
          });

      return {
          data: [],
      };
  }

  async deletePayoutDestination(walletId: string, payoutDestinationId: string):
      Promise<ListPayoutDestinationResponse> {
      const { supplierId } = this;
      await this.connection
          .payoutDestinationDelete(supplierId, walletId, payoutDestinationId);

      return {
          data: [],
      };
  }

  async listPayoutDestinations(walletId: string):
        Promise<ListPayoutDestinationResponse> {
      const { supplierId } = this;

      const { data: response } = await this.connection
          .payoutDestinationList(supplierId, walletId);

      return response;
  }

  async getAllTransactions(walletId: string, args?: PaginatedRequest<{types?: string}>):
    Promise<PaginatedResponse<WalletTransaction>> {
      const { supplierId } = this;
      const parameters = args ?? {};
      const {
          types, from, to, pageToken,
      } = parameters;

      const { data: response } = await this.connection
          .listTransactions(supplierId, walletId, types as string, from, to, pageToken ?? undefined);

      const { data, metadata } = response;
      if (!data) {
          throw AccountingServiceError
              .fromMissingProperty('data');
      }
      if (!metadata) {
          throw AccountingServiceError
              .fromMissingProperty('metadata');
      }
      const transactions = data
          .map(mapTransactionToWalletTransaction);
      return {
          data: transactions,
          nextPageToken: metadata.next_page_token ?? undefined,
      };
  }

  async getAllWallets(): Promise<WalletBalanceType[]> {
      const { supplierId } = this;
      const { data } = await this.connection.walletsV2(supplierId);
      if (!data.data) {
          throw AccountingServiceError.fromMissingProperty('data');
      }
      if (data.data.length === 0) {
          throw AccountingServiceWalletError.fromMissingWallet(supplierId);
      }
      return data.data?.map(fromApiWalletToApplicationWallet);
  }

  async getPaymentProviders(): Promise<PaymentProvider[]> {
      const { supplierId } = this;
      const { data } = await this.connection.paymentProviders(supplierId);
      if (!data.data) {
          throw AccountingServiceError.fromMissingProperty('paymentProviders');
      }
      return data.data.map(fromApiPaymentProviderToApplicationPaymentProvider);
  }

  async setSwitchToPaymentProvider(paymentProviderSwitchRequest: PaymentProviderSwitchRequest):
    Promise<PaymentProvider> {
      const { supplierId } = this;
      const {
          providerId, type, date,
      } = paymentProviderSwitchRequest;
      const data = await this
          .connection
          .switchPaymentProviders(supplierId,
              providerId,
              { type: type as SetActivationIntentTypeEnum, date });
      if (!data?.data) {
          throw AccountingServiceError.fromMissingProperty('paymentProviders');
      }
      return fromApiPaymentProviderToApplicationPaymentProvider(data.data);
  }

  async getPaymentMethods(): Promise<PaymentMethodType[]> {
      const { supplierId } = this;

      const { data } = await this.connection.paymentOptionsGet(supplierId);
      if (!data?.data) {
          throw new Error('Response was malformed');
      }
      return data.data?.map(fromApiPaymentMethodToPaymentMethod);
  }

  async setPaymentMethods(methods: SetPaymentOptionsRequest): Promise<void> {
      const { supplierId } = this;
      const { data } = await this.connection.setPaymentOptions(supplierId, methods);
      if (!data) {
          throw AccountingServiceError.fromMissingRequestProperty('data', 'setPaymentMethods');
      }
  }

  async requestPayoutV2(walletId: string, payoutDestinationId: string, amount: Money): Promise<void> {
      const { supplierId } = this;
      await this.connection
          .payoutCreateV2(supplierId, walletId, { amount, destination_id: payoutDestinationId });
  }
}

export default { AccountingService };
