import {
    handleKeyboardEvent,
} from '@/services/ShortcutService';
import { BkNextDomEventListenerType } from '@/listeners/constants';

const mount = () => {
    window.addEventListener('keydown', handleKeyboardEvent);
    window.addEventListener('keyup', handleKeyboardEvent);
};
const unmount = () => {
    window.removeEventListener('keydown', handleKeyboardEvent);
    window.removeEventListener('keyup', handleKeyboardEvent);
};
export default {
    mount,
    unmount,
} as BkNextDomEventListenerType;
