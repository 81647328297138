import {
    DEMO, LOCAL, DEVELOPMENT, PRODUCTION, SANDBOX, STAGING, ADMINSTAGE,
} from '@/constants/environment';
// Node environment
export const NODE_ENV = import.meta.env.MODE;

// Application settings
export const SPA_BASE_URL = import.meta.env.VITE_APPLICATION_BASE_URL;
export const SPA_BASE_PATH = import.meta.env.VITE_APPLICATION_BASE_PATH || '/';

export const DEFAULT_LOCALE = import.meta.env.VITE_I18N_FALLBACK_LOCALE;
export const LOCAL_STORAGE_PREFIX = import.meta.env.VITE_LOCAL_STORAGE_PREFIX ?? 'bk-local-storage';
export const LOCAL_STORAGE_ENCRYPTION_KEY = import.meta.env.VITE_LOCAL_STORAGE_ENCRYPTION;
export const DESKTOP_VIEWPORT_MIN_SCREEN_WIDTH = 769;

// Keycloak
export const AUTH_SERVICE_URL = import.meta.env.VITE_AUTH_SERVICE_URL;
export const AUTH_REALM = import.meta.env.VITE_AUTH_REALM;
export const SKIP_CHECK_LOGIN_ON_INIT = import.meta.env.VITE_AUTH_SKIP_CHECK_ON_LOGIN ?? false;
export const AUTH_SERVICE_CLIENT = import.meta.env.VITE_AUTH_SERVICE_CLIENT ?? 'js-webclient';

// BookingKit
export const BOOKINGKIT_API_V4_BASE_URL = import.meta.env.VITE_BK_API_V4_BASE_URL;

// Sentry
export const SENTRY_DNS = import.meta.env.VITE_SENTRY_SERVICE;
export const SENTRY_TUNNEL = import.meta.env.VITE_SENTRY_TUNNEL;
export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env.VITE_TRACES_SAMPLE_RATE;
export const SENTRY_TRACING_ORIGINS = import.meta.env.VITE_TRACING_ORIGINS;

export const IS_MICRO_FRONTEND = import.meta.env.VITE_IS_MICRO_FRONTEND ?? false;

// BOOKINGKIT_CLASSIC
export const BOOKINGKIT_CLASSIC = import.meta.env.VITE_BOOKINGKIT_CLASSIC;

// Iframe
export const IFRAME_URL = (() => {
    // Need this for micro frontend, goodbye in 2024
    const bkappBaseUrl = document.getElementById('coppi-app')?.dataset?.baseurl;
    if (bkappBaseUrl) {
        return bkappBaseUrl;
    }
    return import.meta.env.VITE_BOOKINGKIT_CLASSIC_IFRAME ?? 'https://web.admin.bookingkit.com';
})();

export const HELP_URL = 'https://help.bookingkit.com/';

export const REACH_BASE_URL = import.meta.env.VITE_REACH_BASE_URL ?? 'https://reach.bookingkit.net';

export const REACH_LOGIN_URL = import.meta.env.VITE_REACH_LOGIN_URL ?? 'https://reach.bookingkit.net/login';
// Intercom
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

export const POST_HOG_API_HOST = import.meta.env.VITE_POST_HOG_API_HOST;

export const POST_HOG_API_KEY = import.meta.env.VITE_POST_HOG_API_KEY;

export const GTM_ID = import.meta.env.VITE_GTM_ID;
export const WIDGET_ASSET_URL = import.meta.env.VITE_WIDGET_ASSETS_URL;

export const WIDGET_DOCS = `${import.meta.env.VITE_WIDGET_3_0_ELEMENTS_SRC }/docs/installingElements.html#installation`;
