import {
    getEnrichedRoute,
} from '@/router/helpers';
import { SIDEBAR_POSITIONS, BkNextMainModuleRouteInterface } from '@/router/helpers/constants';
import { OVERVIEW_ROUTES_NAMES } from '@/constants/routeNames';
import Manticore from '@/components/Manticore/useManticoreNavigation';
import { OVERVIEW_MODULE } from '@/constants/modules';
import { getModuleMessages } from './locales';
import Home from './index.vue';

const routes = {
    path: `/${OVERVIEW_ROUTES_NAMES.MAIN}`,
    name: OVERVIEW_ROUTES_NAMES.MAIN,
    label: 'sidenav.overview.main.label',
    component: Home,
    meta: {
        manticoreMatches: [/site\/index+\?+.*/, /site\/index/, /\/\?+.*/],
        sidenav: {
            parent: true,
            position: SIDEBAR_POSITIONS.TOP,
            icon: 'LayoutDashboard',
        },
        moduleName: OVERVIEW_MODULE,
        loadModuleAssets: getModuleMessages,
        component: Manticore,
        props: {
            iFrameUrl: 'site/index',
        },
    },
} as BkNextMainModuleRouteInterface;

export default getEnrichedRoute(routes, OVERVIEW_MODULE, getModuleMessages);
