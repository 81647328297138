import { CurrencyType } from '@/constants/currencySymbols';
import { OrderStatusEnum } from '@/constants';

type PaginationType = {
    pageSize?: number,
    asc?: boolean,
    nextPageToken?: string
}

type CancellableRequest = {
    signal ?: AbortSignal
}

export type PaginatedAndCancellableRequest = Partial<PaginationType> & CancellableRequest;

export const ORDER_ITEM_TYPE_TICKET = 'ticket';
export const ORDER_ITEM_TYPE_PRODUCT = 'product';
export const ORDER_ITEM_TYPE_FLEX_TICKET = 'flexTicket';

export type OrderItemTicketType = {
    type: typeof ORDER_ITEM_TYPE_TICKET,
    active: boolean,
    code: string,
    availabilityId: string,
    contact: {
        fullName: string,
        email: string
    }
}

export type MoneyAmount = {
    value: number,
    currency: string
}

export type OrderItemFlexTicketType = {
    type: typeof ORDER_ITEM_TYPE_FLEX_TICKET,
    name?: string,
    id: string,
    redeemCode: string,
    price: MoneyAmount,
}

export type OrderItemProductType = {
    type: typeof ORDER_ITEM_TYPE_PRODUCT,
    name: string,
    id: string,
    quantity: number,

}
export type OrderItem = OrderItemTicketType | OrderItemProductType | OrderItemFlexTicketType;

export type OrderType = {
    id: string,
    code: string,
    name: string,
    status: OrderStatusEnum | undefined,
    amount: number,
    currency: CurrencyType | undefined,
    paymentStatus?: string,
    date: string,
    orderItems: OrderItem[]
}

export type OrderValueStat = {
    startTime?: Date,
    endTime?: Date,
    totalRevenue: MoneyAmount[],
}

export type TicketsCountStat = {
    startTime?: Date,
    endTime?: Date,
    total: number,
}
