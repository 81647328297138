import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { AUTHENTICATION_SERVICE } from '@/bootstrap/ServiceProviders';

export const useAuthenticationService = () => {
    const { logout, hasRole } = applicationContainer.get<AuthenticationServiceInterface>(AUTHENTICATION_SERVICE);
    return {
        logout,
        hasRole: hasRole.bind(hasRole),
    };
};

export default useAuthenticationService();
