import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { computed, ref } from 'vue';
import useUser from '@/state/user';
import {
    DEFAULT_LOCALE, SPA_BASE_PATH,
} from '@/bootstrap/environment';
import { addLocaleMessages, i18n } from '@/i18nSetup';

// Import all initial application routes
import {
    BkNextRouteMeta,
} from '@/router/helpers/constants';
import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { LOGGER_SERVICE } from '@/bootstrap/ServiceProviders';

import {
    SupplierType,
} from '@/models/User';
import { useSupplier } from '@/state/supplier';
import { PostHogService } from '@/services/PostHogService';
import { createSupplierRouteGuard, createModuleGuard } from '@/router/RouteGuards';
import { updateIntercom } from '@/bootstrap/intercom';
import { useActiveModuleService } from '@/composables/useActiveModuleService';
import GeneralRoutes, { ERROR_ROUTES } from './GeneralRoutes';

const { supplier, isReady } = useSupplier();
const { user } = useUser();
const postHogService = new PostHogService();

export const routerRoutes = ref<RouteRecordRaw[]>(GeneralRoutes as unknown as RouteRecordRaw[]);

const router = createRouter({
    history: createWebHistory(SPA_BASE_PATH),
    routes: routerRoutes.value,
});

export const authorisedRoutes = computed(() => {
    const { activeModules } = useActiveModuleService();
    const routeGuard = createSupplierRouteGuard(supplier.value as SupplierType);
    const moduleGuard = createModuleGuard(activeModules.value);

    if (!supplier.value?.scopes) {
        return routerRoutes.value;
    }
    return routerRoutes.value
        .filter(routeGuard)
        .filter(moduleGuard)
        .map((route) => {
            if (!route.children || route.children.length === 0) {
                return route;
            }
            const children = route.children
                .filter(routeGuard)
                .filter(moduleGuard);
            return { ...route, children };
        });
});

router.beforeEach(async (to, from, next) => {
    const { getUserLocale } = useUser();
    const userLocale = getUserLocale();
    const fallbackLocale = DEFAULT_LOCALE;

    // waits the supplier to be set from network request if not already set
    await isReady();
    const routeGuard = createSupplierRouteGuard(supplier.value as SupplierType);

    if (!routeGuard(to)) {
        next({ name: ERROR_ROUTES.UNAUTHORISED });
    }

    if (!to.meta?.loadModuleAssets) {
        next();
    } else {
        const routeMeta = to.meta as unknown as BkNextRouteMeta;

        try {
            const module = await routeMeta.loadModuleAssets(userLocale);
            addLocaleMessages(userLocale, module.default, routeMeta.moduleName);
        } catch (e) {
            const logger = applicationContainer.get<LoggerServiceInterface>(LOGGER_SERVICE);
            logger.captureException(e,
                { extra: { missingLocaleFile: userLocale, module: routeMeta?.moduleName } });
        }

        if (fallbackLocale !== userLocale) {
            try {
                const module = await routeMeta.loadModuleAssets(fallbackLocale);
                addLocaleMessages(fallbackLocale, module.default, routeMeta.moduleName);
            } catch (e) {
                const logger = applicationContainer.get<LoggerServiceInterface>(LOGGER_SERVICE);
                logger.captureException(e, {
                    extra: { missingLocaleFile: fallbackLocale, module: routeMeta?.moduleName },
                });
            }
        }
    }
    next();
});

router.afterEach((to) => {
    document.title = to?.meta?.title ? `${i18n.global?.t(to?.meta?.title as string)} - bookingkit` : 'bookingkit';
    postHogService.captureEvent('Route changed', {
        module: to?.name,
        service_email: user.value?.email,
        vendor_name: supplier.value?.name,
    });
    updateIntercom({ module: to?.name });
});

export const addRoute = (route: RouteRecordRaw): void => {
    if (routerRoutes.value.find((r) => r.name === route.name) === undefined) {
        routerRoutes.value.push(route);
        router.addRoute(route);
    }
};
export const removeRoute = (route: { name: string }): void => {
    if (routerRoutes.value.find((r) => r.name === route.name) !== undefined) {
        routerRoutes.value = routerRoutes.value.filter((r) => r.name !== route.name);
        router.removeRoute(route.name);
    }
};
export default router;
