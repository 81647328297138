import { App, unref } from 'vue';
import { RouterService } from '@/router/RouterService';
import { Router } from 'vue-router';
import { IRouterService } from '@/router/IRouterService';
import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import { LOGGER_SERVICE } from '@/bootstrap/ServiceProviders';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';

let service:IRouterService;

export const NextRoutingPlugin = {
    install: (app: App, router: Router) => {
        const logger = applicationContainer.get<LoggerServiceInterface>(LOGGER_SERVICE);
        service = new RouterService(router, logger);
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$navigateTo = service.navigateTo;
        // eslint-disable-next-line no-param-reassign
        Object.defineProperty(app.config.globalProperties, '$currentRoute', {
            enumerable: true,
            get: () => unref(service.currentRoute),
        });

        app.provide('UseRouterService', { routerService: service });
    },
};

export const useRoutingService = (): IRouterService => {
    if (!service) {
        throw new Error('Service is not instantiated');
    }
    return {
        navigateTo: service.navigateTo,
        activateWithoutNavigation: service.activateWithoutNavigation,
        currentRoute: service.currentRoute,
        navigateToLegacyRoute: service.navigateToLegacyRoute,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        replaceQueryParams: service.replaceRoute,
    };
};
export default { NextRoutingPlugin, useRoutingService };
