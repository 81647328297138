import { deregisterShortcut, registerShortcut } from '@/services/ShortcutService';
import { BkNextDomEventListenerType } from '@/listeners/constants';
import triggerSearchModalShortcut from '@/bootstrap/globalShortcuts/triggerSearchModal';
import triggerVendorSwitchModal from '@/bootstrap/globalShortcuts/triggerVendorSwitchModal';
import triggerAddDateToExperienceModal from '@/bootstrap/globalShortcuts/triggerAddExperienceDateModal';

const mount = () => {
    registerShortcut(triggerSearchModalShortcut.mac);
    registerShortcut(triggerSearchModalShortcut.windows);
    registerShortcut(triggerVendorSwitchModal.mac);
    registerShortcut(triggerVendorSwitchModal.windows);
    registerShortcut(triggerAddDateToExperienceModal.mac);
    registerShortcut(triggerAddDateToExperienceModal.windows);
};

const unmount = () => {
    deregisterShortcut(triggerSearchModalShortcut.mac);
    deregisterShortcut(triggerSearchModalShortcut.windows);
    deregisterShortcut(triggerVendorSwitchModal.mac);
    deregisterShortcut(triggerVendorSwitchModal.windows);
    deregisterShortcut(triggerAddDateToExperienceModal.mac);
    deregisterShortcut(triggerAddDateToExperienceModal.windows);
};

export default {
    mount,
    unmount,
} as BkNextDomEventListenerType;
