export class PageVisibilityActiveCallbackError extends Error {
    constructor(...params: any[]) {
        super(...params);
        this.message = `Error while executing the callback: ${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PageVisibilityActiveCallbackError);
        }

        this.name = 'PageVisibilityActiveCallbackError';
    }
}

export default PageVisibilityActiveCallbackError;
