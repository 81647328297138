import { Label } from '@/services/InventoryService/types';
import { InventoryServiceError } from '@/services/InventoryService/Errors/InventoryServiceError';
import { LabelResponse } from '@bookingkit-private/api-v4/models/label-response';

export const fromApiModelToLabels = (fromApiModelInput: LabelResponse):Label => {
    const {
        id, name,
    } = fromApiModelInput;
    if (id === undefined) {
        throw InventoryServiceError.fromInvalidProperty('id', 'string', id);
    }
    if (name === undefined) {
        throw InventoryServiceError.fromInvalidProperty('name', 'string', name);
    }
    return {
        id,
        name,
    };
};

export default { fromApiModelToLabels };
