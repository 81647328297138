import useApplicationState from '@/state/application';

const callback = (event: Pick<KeyboardEvent, 'preventDefault'>) => {
    const {
        isExperienceListModalActive, activateExperienceListModal, closeModal,
        isPowerShortcutsEnabled,
    } = useApplicationState();
    if (!isPowerShortcutsEnabled()) {
        return;
    }
    if (!isExperienceListModalActive()) {
        closeModal();
        activateExperienceListModal();
        event.preventDefault();
    } else {
        closeModal();
    }
};

const windows = {
    callback,
    keys: ['control', 'd'],
};

const mac = {
    callback,
    keys: ['d', 'meta'],
};

export default {
    windows, mac,
};
