import { SupplierScopeType, SupplierType } from '@/models/User';

export class HasRequiredScopesSpecification {
    // eslint-disable-next-line no-empty-function
    constructor(private scope: SupplierScopeType) { }

    isSatisfiedBy(supplier: SupplierType): boolean {
        return supplier.scopes.includes(this.scope);
    }
}

export default { HasRequiredScopesSpecification };
