<template>
  <div class="toast-layer" :class="{'inMicroFrontend':isMicroFrontend, 'isMobile': isMobile}">
    <c-toast
      v-for="(toastMessage, index) in toastMessages"
      :key="index"
      :intent="toastMessage.intent"
      @close="() => $emit('close-toast', index)"
      :message="toastMessage.message"
      class="c-layer-overlay"
      :action-button-label="toastMessage.actionLabel"
      @handle-action="toastMessage.handleAction"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CToast } from '@bookingkit/coppi';

export default defineComponent({
    name: 'ToastLayer',
    emits: ['close-toast'],
    components: {
        CToast,
    },
    props: {
        toastMessages: {
            required: true,
        },
        isMicroFrontend: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style scoped>
.toast-layer {
  position: fixed;
  bottom: 0;
  right: calc(50%);
  transform: translateX(+50%);
}

.toast-layer.inMicroFrontend,
.toast-layer.isMobile {
  right: 50%;
}

.toast-layer.isMobile {
  bottom: var(--mobile-menu-height);
}

.c-toast-container {
  margin-bottom: var(--s3);
  position: relative;
}
</style>
