<template>
    <div class="page-container">
        <div class="server-error">
            <img :src="NoAuthImage"
                 alt="error"
                 class="error-image" />

            <c-heading type="one"
                       class="error-title">
                {{t('errorPages.notAuthorised.title')}}
            </c-heading>
            <c-running type="one"
                       class="error-description">
                {{t('errorPages.notAuthorised.description')}}
            </c-running>

            <c-button
                class="retry-button"
                @click="navigateToDashboard"
                :label="t('errorPages.notAuthorised.cta')"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { CRunning, CButton, CHeading } from '@bookingkit/coppi';
import useTranslationService from '@/composables/useTranslationService';
import { useRoutingService } from '@/plugins/NextRouting';
import { OVERVIEW_ROUTES_NAMES } from '@/constants';
import NoAuthImage from './NoAuth.svg';

const { t } = useTranslationService({ prefix: 'errors' });

const navigateToDashboard = () => useRoutingService().navigateTo({ name: OVERVIEW_ROUTES_NAMES.MAIN });
</script>

<style scoped>

  .page-container {
      display: flex;
      height: 100%;
  }

  .server-error {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    flex: 1 1 auto;
    grid-column: 2 / 14;
  }

  .error-title {
    margin-top: var(--s6);
  }

  .error-description {
    margin-top: var(--s6);
  }

  .retry-button {
    margin: var(--s6) auto;
  }

  .error-image {
    margin: var(--s1) auto;
  }

</style>
