<template>
    <div>
        <router-view />
    </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>

  .two-list-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

</style>
