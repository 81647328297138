export class CouponServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CouponServiceError);
        }

        this.name = 'CouponServiceError';
    }

    static fromMissingProperty(property:string): CouponServiceError {
        return new CouponServiceError(`Property ${property} is missing in the data`);
    }

    static fromMissingRequestProperty(property: string, request: string): CouponServiceError {
        return new CouponServiceError(`Property ${property} must exists in the payload when using ${request}`);
    }

    static fromMissingSupplier(): CouponServiceError {
        return new CouponServiceError('Supplier service was not able to return an identifier for this supplier during initialisation');
    }
}

export default CouponServiceError;
