import CryptoJs from 'crypto-js';

const encryptContent = (content: string, ENCRYPTION_KEY: string) :string => CryptoJs
    .AES.encrypt(content, ENCRYPTION_KEY).toString();

const decryptContent = (content: string, ENCRYPTION_KEY: string) :string => CryptoJs
    .AES.decrypt(content, ENCRYPTION_KEY).toString(CryptoJs.enc.Utf8);

export const addToStorage = (key: string, payload: any, encryptionKey?: string) => {
    let content = payload;
    try {
        if (typeof payload === 'string') {
            if (encryptionKey) {
                content = encryptContent(payload, encryptionKey);
            }
        }

        if (typeof payload === 'object') {
            content = JSON.stringify(payload);
            if (encryptionKey) {
                content = encryptContent(content, encryptionKey);
            }
        }

        localStorage.setItem(key, content);
        return true;
    } catch (e) {
        return false;
    }
};

export const getFromStorage = (key: string, encryptionKey?: string) => {
    try {
        if (encryptionKey) {
            const content = localStorage.getItem(key) ?? '';
            return decryptContent(content, encryptionKey);
        }
        return localStorage.getItem(key);
    } catch (e) {
        return false;
    }
};

export default { addToStorage, getFromStorage };
