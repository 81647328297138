export const BOOKINGKIT_ROLES = {
    admin: 'bookingkit_admin',
    sales: 'bookingkit_sales',
    service: 'bookingkit_service',
    superAdmin: 'bookingkit_super_admin',
    supplier: 'bookingkit_supplier',
} as const;

export type BookingkitRoleType = typeof BOOKINGKIT_ROLES[keyof typeof BOOKINGKIT_ROLES];

export default {
    BOOKINGKIT_ROLES,
};
