export class NotInitialisedError extends Error {
    constructor(...params: any[]) {
        super(...params);
        this.message = 'KeyCloak instance is not ready. Did you call init() ?';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NotInitialisedError);
        }

        this.name = 'NotInitialisedError';
    }
}

export default NotInitialisedError;
