import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useAuthenticationService } from '@/composables/useAuthenticationService';
import { SupplierType } from '@/models/User';
import { AuthorisationService } from '@/services/AuthorisationService/AuthorisationService';
import { ModuleArrayMapper } from '@/services/ModuleService/types';

export const createSupplierRouteGuard = (supplier: SupplierType) => (route: RouteLocationNormalized|RouteRecordRaw) => {
    if (route.meta?.roleRequired) {
        const { hasRole } = useAuthenticationService();
        if (!hasRole(route.meta?.roleRequired as string)) {
            return false;
        }
    }
    if (!route.meta?.scopesRequired || !Array.isArray(route.meta?.scopesRequired)) {
        return true;
    }
    return AuthorisationService
        .supplierHasAllScopes(
            supplier,
            route.meta.scopesRequired,
        );
};

export const createModuleGuard = (activeModules: ModuleArrayMapper[]) => (route: RouteLocationNormalized|RouteRecordRaw) => {
    const modulesActivated = (activeModules?.map((module) => module.id)).flat();
    if (route?.meta?.modulesToBeActive) {
        return (route?.meta?.modulesToBeActive as string[])?.flat()?.every((moduleId) => modulesActivated?.includes(moduleId));
    }
    return true;
};

export default { createSupplierRouteGuard, createModuleGuard };
