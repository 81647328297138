import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { OrderServiceError }
    from '@/services/OrderService/Errors/OrderServiceError';
import { UseSupplierInterface } from '@/state/supplier';
import {
    fromApiOrderToOrder,
    fromApiOrderToSearchResultOrder,
    fromApiOrderValueToOrderValue,
    fromApiTicketCountToTicketsCount,
} from '@/services/OrderService/Reducers';
import { SearchResult } from '@/components/SearchModal/SearchResultType';
import {
    OrderType,
    OrderValueStat,
    PaginatedAndCancellableRequest, TicketsCountStat,
} from '@/services/OrderService/types';
import {
    CustomSearchOrderRequest,
    InFieldEnum,
    SingleMatchFieldEnum,
} from '@bookingkit-private/api-v4/dist/models';
import { In } from '@bookingkit-private/api-v4/models/in';
import { SingleMatch } from '@bookingkit-private/api-v4/models/single-match';
import { OrderStatusEnum } from '@/constants';
import {
    GetOrderStatsTicketValueRequest,
} from '@bookingkit-private/api-v4/models/get-order-stats-ticket-value-request';

@injectable()
export class OrderService {
  private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

  private supplierId: string;

  constructor(
    @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
    @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
  ) {
      const { supplier } = useSupplierState();
      if (!supplier.value?.id) {
          throw OrderServiceError.fromMissingSupplier();
      }
      this.supplierId = supplier.value.id;
      this.connection = factory.getConnection();
      this.searchOrders = this.searchOrders.bind(this);
  }

  async searchOrders(query: string, params?: PaginatedAndCancellableRequest): Promise<SearchResult[]> {
      const { supplierId } = this;
      const parameters = params ?? {};
      const {
          pageSize, nextPageToken, signal,
      } = parameters;
      const { data } = await this.connection
          .searchOrders(
              supplierId,
              query,
              pageSize,
              0 as unknown as boolean,
              nextPageToken,
              'date',
              { signal },
          );

      if (!data.data) {
          throw OrderServiceError.fromMissingProperty('data.data');
      }
      return data.data.map(fromApiOrderToSearchResultOrder);
  }

  async getFlexibileTicket(
      code: string,
      params?: PaginatedAndCancellableRequest,
  ): Promise<OrderType[]> {
      const { supplierId } = this;
      const parameters = params ?? {};

      const query: CustomSearchOrderRequest = {
          query: [
                {
                    field: SingleMatchFieldEnum.OrderItemsRedeemCode,
                    value: code,
                } as SingleMatch,
          ],
      };
      const {
          pageSize, nextPageToken, signal,
      } = parameters;
      const { data } = await this.connection.customSearchOrder(
          supplierId,
          query,
          pageSize,
            1 as unknown as boolean,
            nextPageToken,
            undefined,
            { signal },
      );
      if (!data.data) {
          throw OrderServiceError.fromMissingProperty('data.data');
      }
      return data.data.map(fromApiOrderToOrder);
  }

  async getAllOrderForAvailabilities(
      availabilities: string[],
      params?: PaginatedAndCancellableRequest,
  ): Promise<OrderType[]> {
      const { supplierId } = this;
      const parameters = params ?? {};

      const query: CustomSearchOrderRequest = {
          query: [
              {
                  field: InFieldEnum.OrderItemsAvailabilityId,
                  values: availabilities,
              } as In,
              {
                  field: InFieldEnum.Status,
                  values: [
                      OrderStatusEnum.Accepted.toLowerCase(),
                      OrderStatusEnum.Open.toLowerCase(),
                      OrderStatusEnum.Reserved.toLowerCase(),
                  ],
              } as In,
          ],
      };
      const {
          pageSize, nextPageToken, signal,
      } = parameters;
      const { data } = await this.connection.customSearchOrder(
          supplierId,
          query,
          pageSize,
          1 as unknown as boolean,
          nextPageToken,
          undefined,
          { signal },
      );
      if (!data.data) {
          throw OrderServiceError.fromMissingProperty('data.data');
      }
      return data.data.map(fromApiOrderToOrder);
  }

  async getOrderValueStats(
      startTime: string,
      endTime: string,
      experiences?: {id: string, options?: string[]}[],
      params?: PaginatedAndCancellableRequest,
  ):Promise<OrderValueStat[]> {
      const { supplierId } = this;
      const parameters = params ?? {};
      const {
          pageSize, nextPageToken, signal,
      } = parameters;
      const request: GetOrderStatsTicketValueRequest = {
          local_start_time: startTime,
          local_end_time: endTime,
          experiences,
      };
      const { data } = await this.connection.getOrderStatsTicketValue(
          supplierId,
          request,
          pageSize,
          1 as unknown as boolean,
          undefined,
          nextPageToken,
          { signal },
      );
      if (!data.data) {
          throw OrderServiceError.fromMissingProperty('data.data');
      }
      return data.data.map(fromApiOrderValueToOrderValue);
  }

  async getTicketsCountStats(
      startTime: string,
      endTime: string,
      experiences?: {id: string, options?: string[]}[],
      params?: PaginatedAndCancellableRequest,
  ):Promise<TicketsCountStat[]> {
      const { supplierId } = this;
      const parameters = params ?? {};
      const {
          pageSize, nextPageToken, signal,
      } = parameters;
      const request: GetOrderStatsTicketValueRequest = {
          local_start_time: startTime,
          local_end_time: endTime,
          experiences,
      };
      const { data } = await this.connection.getOrderStatsTicketCount(
          supplierId,
          request,
          pageSize,
            1 as unknown as boolean,
            undefined,
            nextPageToken,
            { signal },
      );
      if (!data.data) {
          throw OrderServiceError.fromMissingProperty('data.data');
      }
      return data.data.map(fromApiTicketCountToTicketsCount);
  }
}

export default { OrderService };
