import {
    createI18n, LocaleMessages, VueMessageType,
} from 'vue-i18n';
import { DEFAULT_LOCALE } from '@/bootstrap/environment';

type ModuleLanguageTuple = { module: string, language: string}

const loadedLanguages: ModuleLanguageTuple[] = [];

const hadLoadedMessagesForModule = (module: string, locale: string):boolean => loadedLanguages
    .filter((x) => x.module === module && x.language === locale).length > 0;

/**
 * Accessibility. Set document language
 * @param lang
 * @returns {*}
 */
// eslint-disable-next-line no-return-assign
const setDocumentLanguage = (lang: string) => document.documentElement.lang = lang;

/**
 * Load locale messages
 * Synchronously load base locale files
 */
const loadLocaleMessages = (): ApplicationMessages => {
    const dictionaryModule: Record<string, { default: ApplicationMessages }> = import.meta.glob('./locales/*.json', { eager: true });
    const messages = {} as ApplicationMessages;
    // eslint-disable-next-line no-restricted-syntax
    for (const path in dictionaryModule) {
        if (path) {
            const matched = path.match(/([a-z0-9-_]{2,6})\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                messages[locale] = dictionaryModule[path]?.default;
            }
        }
    }

    return messages;
};

/**
 *  get i18n Singleton
 */
export const i18n = createI18n<false>({
    allowComposition: true,
    globalInjection: true,
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    messages: loadLocaleMessages() as LocaleMessages<VueMessageType>,
    sfc: true,
    legacy: false,
}, false);

export const addLocaleMessages = (locale: ApplicationLocale, messages: ApplicationMessages, module = 'core'): void => {
    if (hadLoadedMessagesForModule(module, locale)) {
        return;
    }
    i18n.global.mergeLocaleMessage(locale, messages);
    loadedLanguages.push({ language: locale, module });
};

/**
 * Set application language
 */
export const changeLocale = (locale: any): void => {
    i18n.global.locale.value = locale;
};

export default i18n;
