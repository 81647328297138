import { BkNextMainModuleRouteInterface, BkNextRouteInterface } from '@/router/helpers/constants';

/**
 * It adds a redirect key to the route object
 * taking it from the first route child if available.
 *
 * @param routeWithChildren
 * @returns {{redirect: string}}
 */
const replaceParamsOfFirstChild = (routeWithChildren: BkNextMainModuleRouteInterface | BkNextRouteInterface) => {
    if (routeWithChildren?.children?.length && routeWithChildren.children[0]?.path !== '') {
        return routeWithChildren?.children[0]?.path.indexOf('/') === 0
            ? `${routeWithChildren?.children[0]?.path.replace('/:code', '')}`
            : (`${routeWithChildren.path}/${routeWithChildren?.children[0]?.path}`);
    }
    return `${routeWithChildren.path}`;
};

export const redirectToFirstChild = (routeWithChildren: BkNextMainModuleRouteInterface | BkNextRouteInterface) => ({
    ...routeWithChildren,
    redirect:
        routeWithChildren?.children?.length && routeWithChildren.children[0]?.path !== ''
            ? replaceParamsOfFirstChild(routeWithChildren)
            : `${routeWithChildren.path}`,
});

/**
 * It adds a meta key with the module name,
 * to the routes of the routeObject including child routes.
 * If passed a function as third argument it sets the ASYNC method
 * to load assets
 * @param routeObject
 * @param moduleName
 * @param loadModuleAssets
 * @returns {{meta: {moduleName: *}}}
 */
export const exportAsModule = (
    routeObject:BkNextRouteInterface,
    moduleName:string,
    loadModuleAssets: (argument: ApplicationLocale) => Promise<{ default: any; }>,
) : BkNextRouteInterface => {
    if (routeObject.children) {
        // eslint-disable-next-line no-param-reassign
        routeObject.children = routeObject.children.map((route) => ({
            ...route,
            meta: {
                ...route?.meta,
                moduleName,
                loadModuleAssets,
            },
        } as BkNextRouteInterface));
    }
    return {
        ...routeObject,
        meta: {
            ...routeObject.meta,
            moduleName,
            loadModuleAssets,
        },
    };
};

/**
 * It add redirect and module name to the route and child routes
 * @param route
 * @param moduleName
 * @param loadModuleAssets
 * @returns {{meta: {moduleName: *}}}
 */
export const getEnrichedRoute = (
    route: BkNextRouteInterface,
    moduleName:string,
    loadModuleAssets: (locale: ApplicationLocale) => Promise<{ default: any }>,
) => exportAsModule(
    redirectToFirstChild(route),
    moduleName,
    loadModuleAssets,
);

export default getEnrichedRoute;
