<template>
    <div style="width: 80%; margin: auto; display: flex; flex-direction: column;">
        <h1>I will be a {{ $route.name }} page soon...</h1>
    </div>
</template>

<script>

import { useI18n } from 'vue-i18n';

export default {
    name: 'template.vue',

    setup() {
        const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global',
        });

        return { t };
    },

};
</script>

<style scoped>

.two-list-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

</style>
