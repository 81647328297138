export const twoDigitsToIsoLanguageCodes = {
    de: 'de-DE',
    en: 'en',
    cs: 'cs-CZ',
    fr: 'fr-FR',
    nl: 'nl-NL',
    fi: 'fi-FI',
    da: 'da-DK',
    es: 'es-ES',
    it: 'it-IT',
    no: 'nn-NO',
    sv: 'sv-SE',
} as const;

export const ApplicationLocales = {
    csCz: 'cs-CZ',
    daDk: 'da-DK',
    deDe: 'de-DE',
    enDev: 'en',
    esEs: 'es-ES',
    enXY: 'en-XY',
    fiFi: 'fi-FI',
    frFr: 'fr-FR',
    itIt: 'it-IT',
    nlNl: 'nl-NL',
    noNo: 'nn-NO',
    svSe: 'sv-SE',
} as const;
