import { inject, injectable } from 'inversify';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { UseSupplierInterface } from '@/state/supplier';
import { InventoryServiceError } from '@/services/InventoryService/Errors/InventoryServiceError';
import { fromApiModel as mapExperiencesFromApi } from '@/services/InventoryService/Reducers/ExperienceReducer';
import {
    fromApiModel as mapExperienceAvailabilityFromApi,
} from '@/services/InventoryService/Reducers/ExperienceAvailabilityReducer';
import { Experience, Label, PaginatedRequest } from '@/services/InventoryService/types';
import { fromApiModelToLabels } from '@/services/InventoryService/Reducers/LabelsReducer';
import { CreateLabelRequest, UpdateLabelRequest } from '@bookingkit-private/api-v4/dist/models';

type GetExperienceAvailabilities = Partial<PaginatedRequest> & {
    experienceId: string,
    startAt: Date,
    endsAt: Date,
    deltaSince?: Date,
    options?: string[],
    requestOptions?: any
}

type PatchAvailabilityRequest = {
    experienceId: string,
    availabilityId: string,
    totalCapacity?: number,
    labels?: string[],
}

@injectable()
export class InventoryService {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    private supplierId: string;

    constructor(@inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
                @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface) {
        const { supplier } = useSupplierState();
        if (!supplier.value?.id) {
            throw InventoryServiceError.fromMissingSupplier();
        }
        this.supplierId = supplier.value.id;
        this.connection = factory.getConnection();
        this.getExperiences = this.getExperiences.bind(this);
    }

    public async patchAvailability(patchAvailabilityInput: PatchAvailabilityRequest) {
        const { supplierId } = this;
        const {
            experienceId, availabilityId, totalCapacity, labels,
        } = patchAvailabilityInput;
        await this.connection
            .patchExperienceAvailability(supplierId, experienceId, availabilityId, {
                ...(totalCapacity !== undefined) && { total_capacity: totalCapacity },
                ...(labels) && { labels },
            });
    }

    public async getExperiences({ active = 1, ...getExperiencesInput }: {active?: 1 | 0} & Partial<PaginatedRequest>): Promise<{ experiences: Experience[], nextPageToken?: string }> {
        const { supplierId } = this;
        const {
            pageSize, nextPageToken, asc,
        } = getExperiencesInput;
        const { data } = await this.connection
            .listExperiences(supplierId, active, pageSize, asc as boolean, nextPageToken);
        if (!data) {
            throw InventoryServiceError.fromInvalidProperty('data', 'object', data);
        }
        if (!data?.data) {
            throw InventoryServiceError.fromInvalidProperty('data.data', 'object', data?.data);
        }
        return {
            experiences: data.data.map(mapExperiencesFromApi),
            nextPageToken: data.metadata?.next_page_token,
        };
    }

    public async getExperienceAvailabilities(getExperienceAvailabilitiesInput: GetExperienceAvailabilities) {
        const { supplierId } = this;
        const {
            experienceId,
            pageSize,
            nextPageToken,
            asc,
            deltaSince,
            endsAt,
            startAt,
            options,
            requestOptions,
        } = getExperienceAvailabilitiesInput;
        const { data } = await this.connection
            .listExperienceAvailabilities(
                supplierId,
                experienceId,
                startAt.toISOString(),
                endsAt.toISOString(),
                options ? options.toString() : undefined,
                deltaSince ? deltaSince.toISOString() : undefined,
                pageSize,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                asc ? 1 : 0,
                nextPageToken,
                requestOptions,
            );

        if (!data.data) {
            throw InventoryServiceError.fromInvalidProperty('data.data', 'object', data?.data);
        }
        return {
            dates: data.data.map(mapExperienceAvailabilityFromApi),
            nextPageToken: data.metadata?.next_page_token,
        };
    }

    public async getLabels(pageSize = 10):Promise<Label[]> {
        const { supplierId } = this;
        const { data } = await this.connection.listLabels(supplierId, pageSize);
        if (!data?.data) {
            throw InventoryServiceError.fromInvalidProperty('data.data', 'object', data?.data);
        }
        return data.data.map(fromApiModelToLabels);
    }

    public async createLabel(createLabelRequest: CreateLabelRequest) {
        const { supplierId } = this;
        await this.connection.createLabel(supplierId, createLabelRequest);
    }

    public async updateLabel(labelId: string, updateLabelRequest: UpdateLabelRequest) {
        const { supplierId } = this;
        await this.connection.updateLabel(supplierId, labelId, updateLabelRequest);
    }

    public async deleteLabel(labelId: string) {
        const { supplierId } = this;
        await this.connection.deleteLabel(supplierId, labelId);
    }
}

export default { InventoryService };
