import visibilityChangesListener from '@/listeners/visibility';
import keyboardEventListener from '@/listeners/keyboard';
import globalShortcuts from '@/bootstrap/globalShortcuts';

export const init = (): void => {
    visibilityChangesListener.mount();
    keyboardEventListener.mount();
    globalShortcuts.mount();
};

export default init;
