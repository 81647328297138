import { LOCAL_STORAGE_PREFIX } from '@/bootstrap/environment';
import { addToStorage, getFromStorage } from '@/services/localStorage';

const PREFIX = LOCAL_STORAGE_PREFIX ?? 'bk-app';

export const addShortcutsActiveStateToStorage = (value: boolean) => {
    const key = `${PREFIX}-DISABLE-SHORTCUTS`;
    addToStorage(key, value);
};

export const addShortcutsToastStateToStorage = (value: boolean) => {
    const key = `${PREFIX}-SHORTCUT-TOAST-SHOWED`;
    addToStorage(key, value);
};

export const getShortcutsToastStateToStorage = () => {
    const key = `${PREFIX}-SHORTCUT-TOAST-SHOWED`;
    return getFromStorage(key) ? getFromStorage(key) : false;
};

export const getShortcutsActiveStateFromStorage = () => {
    const key = `${PREFIX}-DISABLE-SHORTCUTS`;
    const value = getFromStorage(key);
    if (value) {
        return value === 'true';
    }
    return true;
};

export default {
    addShortcutsActiveStateToStorage,
    getShortcutsActiveStateFromStorage,
};
