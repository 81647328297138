type UseTranslationServiceConfig = {
  prefix?: string
}

export const resolveIdentifyPath = (
    key: string,
    prefix: string,
) => {
    const prefixToUse = !prefix.length || prefix.endsWith('.') ? prefix : `${prefix}.`;
    const keyToUse = !prefix.length && key.startsWith('.') ? key.slice(1) : key;
    return (prefixToUse + keyToUse).replaceAll('.', '_');
};

export default function useIdentifyService(options?:UseTranslationServiceConfig) {
    const prefixedIdentify = (key: string) => resolveIdentifyPath(
        key,
        options?.prefix ?? '',
    );

    return {
        identify: prefixedIdentify,
    };
}
