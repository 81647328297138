import { LOCAL_STORAGE_PREFIX } from '@/bootstrap/environment';
import { addToStorage, getFromStorage } from '@/services/localStorage';

const PREFIX = LOCAL_STORAGE_PREFIX ?? 'bk-app';
const CALENDAR_SETTINGS_KEY = `${PREFIX}-CALENDAR-SETTINGS`;

type CalendarSetting = {
    IMAGE_SETTINGS : boolean,
    APPOINTMENT_GROUPING: number,
    SHOW_INACTIVE_EXP: boolean
};

const getCalendarSettingsFromStorage = () => {
    try {
        return JSON.parse(getFromStorage(CALENDAR_SETTINGS_KEY) as string);
    } catch (e) {
        return undefined;
    }
};
export const addCalendarImageSettingsToStorage = (value: boolean) => {
    const calendarObjFromStorage = getCalendarSettingsFromStorage();
    const calendarObj:CalendarSetting = calendarObjFromStorage ? { ...calendarObjFromStorage, IMAGE_SETTINGS: value } : { IMAGE_SETTINGS: value };
    addToStorage(CALENDAR_SETTINGS_KEY, calendarObj);
};

export const getCalendarImageSettingFromStorage = () => {
    const calendarObj = getCalendarSettingsFromStorage();
    return !(calendarObj === null || calendarObj === undefined) ? calendarObj.IMAGE_SETTINGS : true;
};

export const addCalendarGroupingSettingsToStorage = (value: number) => {
    const calendarObjFromStorage = getCalendarSettingsFromStorage();
    const calendarObj:CalendarSetting = calendarObjFromStorage ? { ...calendarObjFromStorage, APPOINTMENT_GROUPING: value } : { APPOINTMENT_GROUPING: value };
    addToStorage(CALENDAR_SETTINGS_KEY, calendarObj);
};

export const getCalendarGroupingSettingFromStorage = () => {
    const calendarObj = getCalendarSettingsFromStorage();
    return !(calendarObj === null || calendarObj === undefined) ? calendarObj.APPOINTMENT_GROUPING || 60 : 60;
};

export const addCalendarInactiveExpSettingsToStorage = (value: number) => {
    const calendarObjFromStorage = getCalendarSettingsFromStorage();
    const calendarObj:CalendarSetting = calendarObjFromStorage ? { ...calendarObjFromStorage, SHOW_INACTIVE_EXP: value } : { SHOW_INACTIVE_EXP: value };
    addToStorage(CALENDAR_SETTINGS_KEY, calendarObj);
};

export const getCalendarInactiveExpSettingsFromStorage = () => {
    const calendarObj = getCalendarSettingsFromStorage();
    return !(calendarObj === null || calendarObj === undefined) ? calendarObj.SHOW_INACTIVE_EXP ?? false : false;
};

export default {
    addCalendarImageSettingsToStorage,
    getCalendarImageSettingFromStorage,
    addCalendarGroupingSettingsToStorage,
    getCalendarGroupingSettingFromStorage,
    addCalendarInactiveExpSettingsToStorage,
    getCalendarInactiveExpSettingsFromStorage,
};
