export class AccountingServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AccountingServiceError);
        }

        this.name = 'AccountingServiceError';
    }

    static fromMissingProperty(property:string): AccountingServiceError {
        return new AccountingServiceError(`Property ${property} is missing in the data`);
    }

    static fromMissingRequestProperty(property: string, request: string): AccountingServiceError {
        return new AccountingServiceError(`Property ${property} must exists in the payload when using ${request}`);
    }

    static fromMissingSupplier(): AccountingServiceError {
        return new AccountingServiceError('Supplier service was not able to return an identifier for this supplier during initialisation');
    }
}

export default AccountingServiceError;
