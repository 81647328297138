import { useSupplier } from '@/state/supplier';
import { SUPPLIER_SWITCH } from '@/constants';
import useUser from '@/state/user';
import { SupplierServiceInterface } from '@/models/Services/SupplierServiceInterface';
import { SupplierType } from '@/models/User';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';

type UseUserSupplierInput = {
    supplierService: SupplierServiceInterface,
    loggerService: LoggerServiceInterface,
};

export const useUserSupplier = (useSupplierInput:UseUserSupplierInput) => {
    const { supplierService } = useSupplierInput;
    const { setCanSwitchSupplier } = useUser();
    const {
        setSupplier, setDefaultSupplierList,
    } = useSupplier();

    const getCurrentSupplier = async (): Promise<SupplierType> => {
        const supplierData = await supplierService.getCurrentSupplierFromRemote();
        setSupplier(supplierData);
        return supplierData;
    };

    const listSuppliers = async () => {
        const listTwoSuppliers = await supplierService.listSuppliers(SUPPLIER_SWITCH.MAX_DEFAULT_SUPPLIERS + 1);
        if (setDefaultSupplierList) {
            setDefaultSupplierList(listTwoSuppliers);
        }
        if (listTwoSuppliers?.length > 1) {
            setCanSwitchSupplier(true);
        } else {
            setCanSwitchSupplier(false);
        }
    };

    const setVendorWithID = async (ident:string) => {
        await supplierService.setSupplierOnRemote({ id: ident });
    };

    return {
        getCurrentSupplier,
        listSuppliers,
        setVendorWithID,
    };
};

export default useUserSupplier;
