export class ModuleServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ModuleServiceError);
        }

        this.name = 'OrderServiceError';
    }

    static fromMissingSupplier(): ModuleServiceError {
        return new ModuleServiceError('Supplier service was not able to return an identifier for this supplier during initialisation');
    }

    static fromMissingModules(): ModuleServiceError {
        return new ModuleServiceError('Listing active modules have failed');
    }
}

export default ModuleServiceError;
