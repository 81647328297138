import { loadIntercom } from '@/bootstrap/intercom';
import { UI_TESTING } from '@/constants';
import { init as initUXListeners } from './uxListeners';

export const initDependencies = async (): Promise<void> => {
    initUXListeners();

    if (import.meta.env.MODE !== UI_TESTING) {
        loadIntercom();
    }
    return Promise.resolve();
};

export default initDependencies;
