{
  "c": "BÈTA",
  "calendar": {
    "addAppointment": "Afspraak toevoegen",
    "downloadChecklist": "Checklist",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Geen overeenkomende activiteiten",
        "noExperiences": "Geen overeenkomende activiteiten",
        "noInactiveExperienceMatchingFilter": "Geen overeenkomende inactieve ervaringen"
      }
    },
    "warnings": {
      "noExperiences": "Geen overeenkomende activiteiten"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Snelle acties"
        }
      },
      "navAccount": {
        "help": {
          "label": "Help"
        },
        "switch": {
          "label": "Overschakelen naar"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "dag | Dagen",
    "hours": "uur | uren",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Ga naar overzicht",
        "description": "Uw account beschikt niet over de rechten die nodig zijn om deze pagina te bekijken",
        "title": "Het is u niet toegestaan om deze pagina te bekijken"
      },
      "serverError": {
        "cta": "Probeer het nog een keer",
        "description": "Door een interne serverfout konden we uw aanvraag niet voltooien. We zijn hiervan op de hoogte gebracht. Probeert u het later nog een keer.",
        "title": "Oeps, er gaat iets mis"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Verkoper heeft geen portemonnees geactiveerd"
      }
    },
    "validationErrors": {
      "invalidIban": "Ongeldige IBAN",
      "notAValidEmail": "Het veld is geen geldige e-mail",
      "notAValidMoneyAmount": "Ongeldig geldbedrag",
      "notEmpty": "Dit veld mag niet leeg zijn",
      "notIdenticalIban": "Bevestiging van IBAN moet hetzelfde zijn als IBAN",
      "unrecognisedDateTime": "Datum en tijd niet herkend"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Kies de activiteit waaraan u nieuwe beschikbaarheid wilt toekennen.",
      "title": "Afspraak aan de activiteit toevoegen"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Vernieuwen",
      "title": "Er is een nieuwe versie van bookingkit beschikbaar"
    }
  },
  "pagination": {
    "next": "Volgende",
    "previous": "Vorige"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Afspraak toevoegen",
    "disableHere": "Sneltoetsen hier uitschakelen",
    "enableShortcuts": "Sneltoetsen activeren",
    "keyboardShortcuts": "Sneltoetsen",
    "ok": "OK",
    "powerShortcuts": "Sneltoetsen",
    "search": "Zoeken",
    "searchShortcutToastInfoMac": "Druk nogmaals op ⌘ + F om de native browserzoekfunctie te activeren",
    "searchShortcutToastInfoWindows": "Druk nogmaals op Ctrl + F om de native browserzoekfunctie te activeren",
    "toastMessage": "Sneltoetsen zijn standaard geactiveerd.",
    "vendorShortcutToastInfoMac": "Druk nogmaals op ⌘ + M om de native browsersneltoets te activeren",
    "vendorShortcutToastInfoWindows": "Druk nogmaals op Ctrl + M om de native browsersneltoets te activeren",
    "vendorSwitch": "Andere verkoper"
  },
  "search": {
    "accepted": "GEACCEPTEERD",
    "archived": "GEARCHIVEERD",
    "canceled": "GEANNULEERD",
    "coupon": "Coupon",
    "declined": "AFGEWEZEN",
    "expired": "VERLOPEN",
    "failure": "FOUT",
    "feature": "Functie",
    "flexibleTicket": "Flexibel ticket",
    "invalid": "Ongeldig",
    "noResultFound": "Geen resultaat gevonden",
    "noResultFoundMessage": "Je zoekopdracht heeft geen resultaten opgeleverd. Probeer het op een andere manier.",
    "open": "OPENEN",
    "order": "Volgorde",
    "pending": "IN BEHANDELING",
    "reserved": "GERESERVEERD",
    "search": "Zoeken",
    "searchEntryMessage": "U kunt zoeken:",
    "searchError": "Er is iets misgegaan..",
    "searchErrorMessage": "We proberen het probleem op te lossen. Probeer het binnenkort opnieuw.",
    "searchFeatureEntryMessage": "Functies op namen.",
    "searchOrderEntryMessage": "Bestellingen op id's, klant of andere aan de bestelling gerelateerde gegevens;",
    "searchScopes": {
      "all": "Alles",
      "coupons": "Coupons",
      "features": "Functies",
      "flexibleTickets": "Flexibele tickets",
      "orders": "Bestellingen",
      "vouchers": "Vouchers"
    },
    "searchVoucherEntryMesaage": "Coupons per code, titel of beschrijving;",
    "searchVoucherEntryMessage": "Coupons per code, titel of beschrijving;",
    "ticketTypes": {
      "all": "Alles",
      "coupons": "Coupons",
      "features": "Functies",
      "flexibleTickets": "Flexibele tickets",
      "orders": "Bestellingen",
      "vouchers": "Vouchers"
    },
    "valid": "Geldig",
    "voucher": "Voucher"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Bedrijfsgegevens"
      },
      "contract": {
        "label": "Contract"
      },
      "main": {
        "label": "account"
      },
      "profile": {
        "label": "Profiel"
      },
      "signOut": {
        "label": "Afmelden"
      },
      "userManagement": {
        "label": "Gebruikersbeheer"
      },
      "vendorSwitcher": {
        "label": "overstappen op andere verkoper"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API-betalingen"
      },
      "applicaitonLimits": {
        "label": "Applicatiebeperkingen"
      },
      "application": {
        "label": "Toepassing"
      },
      "applicationLimits": {
        "label": "Applicatiebeperkingen"
      },
      "changeRates": {
        "label": "Tarieven wijzigen"
      },
      "chargeVouchers": {
        "label": "Coupons aanrekenen"
      },
      "claimAccount": {
        "label": "Account claimen"
      },
      "createAccount": {
        "label": "Account maken"
      },
      "dashboard": {
        "label": "Dashboard"
      },
      "disableEventDates": {
        "label": "Datums van activiteiten uitschakelen"
      },
      "emailConfiguration": {
        "label": "E-mail configureren"
      },
      "importCoupons": {
        "label": "Coupons importeren"
      },
      "importOrders": {
        "label": "Bestellingen importeren"
      },
      "importVouchers": {
        "label": "Coupons importeren"
      },
      "inviteNew": {
        "label": "Een nieuwe reseller uitnodigen"
      },
      "invoices": {
        "label": "Facturen"
      },
      "KYCUnverified": {
        "label": "KYC niet geverifieerd"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "API beheren"
      },
      "manageBkUsers": {
        "label": "Bk-gebruikers beheren"
      },
      "mangopayDisputes": {
        "label": "Mangopay-geschillen"
      },
      "marketingPartners": {
        "label": "Marketingpartners"
      },
      "outstandingPayments": {
        "label": "Uitstaande betalingen"
      },
      "paymentRequests": {
        "label": "Betaalverzoeken"
      },
      "payoutChannels": {
        "label": "Uitbetalingskanalen"
      },
      "paypalPaymentRequests": {
        "label": "PayPal-betaalverzoeken"
      },
      "platforms": {
        "label": "Platformen"
      },
      "provider": {
        "label": "Aanbieder"
      },
      "rates": {
        "label": "Tarieven"
      },
      "resellerPaymentStatus": {
        "label": "Betalingsstatus van reseller"
      },
      "setupFee": {
        "label": "Opstartkosten"
      },
      "translationTool": {
        "label": "Vertaaltool"
      },
      "unknownPaymentrequests": {
        "label": "Onbekende betalingsverzoeken"
      }
    },
    "beta": "BÈTA",
    "calendar": {
      "day": {
        "label": "Dagoverzicht"
      },
      "feed": {
        "label": "Kalenderfeed"
      },
      "main": {
        "label": "Kalender"
      },
      "month": {
        "label": "Maandoverzicht"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Bundels"
      },
      "experiences": {
        "label": "Ervaringen"
      },
      "flexibleOffers": {
        "label": "Flexibele aanbiedingen"
      },
      "main": {
        "label": "Inventaris"
      },
      "products": {
        "label": "Producten"
      },
      "resources": {
        "label": "Middelen"
      }
    },
    "marketplace": {
      "main": {
        "label": "App Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Flexibele tickets"
      },
      "main": {
        "label": "Bestellingen"
      },
      "orders": {
        "label": "Bestellingen"
      },
      "participants": {
        "label": "Deelnemers"
      },
      "promotionalCoupons": {
        "label": "Promotionele coupons"
      },
      "requests": {
        "label": "Verzoeken"
      },
      "vouchers": {
        "label": "Vouchers"
      }
    },
    "overview": {
      "main": {
        "label": "Overzicht"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Gelieerde links"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Een nieuwe reseller uitnodigen"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Mijn partners"
      },
      "partnerRates": {
        "label": "Partner tarieven"
      },
      "platforms": {
        "label": "Platformen"
      },
      "promote": {
        "label": "Promoot lokale ervaringen"
      },
      "reachOrders": {
        "label": "Orders"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Statistieken samenvoegen"
      },
      "exports": {
        "label": "Exports"
      },
      "main": {
        "label": "Rapporten"
      },
      "monthlyStatement": {
        "label": "Maandelijks overzicht"
      },
      "statements": {
        "label": "Overzichten"
      },
      "statistics": {
        "label": "Statistieken"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Resellers"
      },
      "marketplace": {
        "label": "Marktplaats"
      },
      "myPartners": {
        "label": "Mijn partners"
      },
      "orders": {
        "label": "Bestellingen"
      },
      "platforms": {
        "label": "Integraties van wederverkopers"
      },
      "promote": {
        "label": "Lokale ervaringen promoten"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Afrekenen configureren "
      },
      "emailCampaigns": {
        "label": "E-mailcampagnes"
      },
      "localExperiences": {
        "label": "Lokale ervaringen"
      },
      "main": {
        "label": "Verkopen"
      },
      "newWidgets": {
        "label": "Nieuwe widgets-configurator"
      },
      "paymentOptions": {
        "label": "Betaalmethoden"
      },
      "paymentProviders": {
        "label": "Betalingsaanbieders"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kalender feed"
      },
      "emailConfiguration": {
        "label": "E-mail configureren"
      },
      "invoiceLayout": {
        "label": "Indeling factuur"
      },
      "invoiceNNumber": {
        "label": "Factuurnummer"
      },
      "invoiceNumber": {
        "label": "Factuurnummer"
      },
      "main": {
        "label": "Instellingen"
      },
      "modules": {
        "label": "Modules"
      },
      "ticketConfiguration": {
        "label": "Ticket configureren "
      },
      "translations": {
        "label": "Vertalingen"
      },
      "vat": {
        "label": "Btw"
      },
      "voucherConfiguration": {
        "label": "Voucher configureren"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App Marketplace"
      },
      "appointment": {
        "label": "Afspraak toevoegen"
      },
      "booking": {
        "label": "Boeking"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "Bookingkit Classic"
      },
      "bookingKitReach": {
        "label": "bookingkit reach"
      },
      "contactSupport": {
        "label": "Contact opnemen met ondersteuning"
      },
      "copyToClipboard": {
        "failure": "Kan link voor leverancier niet kopiëren",
        "success": "Specifieke link gekopieerd voor leverancier:"
      },
      "date": {
        "label": "Datum"
      },
      "experience": {
        "label": "Ervaring"
      },
      "knowledgeBase": {
        "label": "Kennisbank",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Onboarding-wizard (NTB)"
      },
      "powerShortcuts": {
        "label": "Sneltoetsen"
      }
    },
    "updates": {
      "main": {
        "label": "Productupdates"
      },
      "mainBadge": {
        "label": "Nieuw"
      }
    },
    "wallet": {
      "main": {
        "label": "Portemonnee"
      },
      "partnerPayments": {
        "label": "Betalingen aan partners"
      },
      "verification": {
        "label": "Verificatie"
      },
      "walletManagement": {
        "label": "Portemonneebeheer"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Beschikbare verkopers",
    "dismiss": "Negeren",
    "navigate": "Navigeren",
    "noResult": "Geen resultaat gevonden",
    "recentUsed": "Onlangs gebruikt",
    "resultsLabel": "Verkopers gevonden",
    "searchError": "Er is iets misgegaan..",
    "searchErrorMessage": "We proberen het probleem op te lossen. Probeer het binnenkort opnieuw.",
    "searchForVendor": "Zoeken naar verkoper",
    "select": "Selecteren",
    "storeResultsLabel": "Beschikbare verkopers",
    "supplierAlreadyInUseError": "Je gebruikt momenteel de rekening van deze verkoper",
    "switchVendor": "Overstappen naar een andere verkoper",
    "wishmorning": "Goedemorgen"
  }
}