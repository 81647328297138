export const CALENDAR_ROUTE_NAMES = {
    MAIN: 'calendar',
    DAY: 'day-view',
    DAY_BETA: 'day-view-b3t@',
    MONTH: 'month-view',
};
export const ORDER_ROUTES_NAMES = {
    MAIN: 'order',
    ORDERS: 'orders',
    ORDER_DETAILS: 'order-details',
    REQUESTS: 'requests',
    REQUESTS_DETAILS: 'requests-details',
    VOUCHERS: 'vouchers',
    VOUCHERS_DETAILS: 'voucher-details',
    PARTICIPANTS: 'participants',
    COUPONS: 'coupons',
    COUPONS_DETAILS: 'coupon-details',
    FLEXIBLE_TICKETS: 'flexible-tickets',
    FLEXIBLE_TICKETS_DETAILS: 'flexible-tickets-details',
};
export const INVENTORY_ROUTES_NAMES = {
    MAIN: 'inventory',
    EXPERIENCES: 'experiences',
    EXPERIENCE_DETAIL: 'experience_detail',
    EXPERIENCE_PRICING: 'experience_pricing',
    OFFERS: 'flexible-offers',
    OFFERSDUPLICATE: 'flexible-offers-duplicate',
    PRODUCTS: 'products',
    BUNDLES: 'bundles',
    RESOURCES: 'resources',
};
export const REPORTS_ROUTES_NAMES = {
    MAIN: 'reports',
    MONTHLYSTATEMENT: 'monthly-statement',
    STATEMENTS: 'statements',
    STATISTICS: 'statistics',
    EXPORTS: 'exports',
    AGGREGATESTATISTICS: 'aggregate-statistics',
};
export const WALLET_ROUTES_NAMES = {
    MAIN: 'wallet-management',
    WALLET: 'wallet-management',
    MANGOPAY_WALLET: 'mangopay-wallet',
    VERIFICATION: 'verification',
    PARTNER_PAYMENTS: 'partner_payments',
};
export const MARKETPLACE_ROUTES_NAMES = {
    PARENT: 'app-marketplace-parent',
    MAIN: 'app-marketplace',
    APP_DETAIL: 'app-detail',
    APP_PLACEHOLDER: 'app',
};
export const REACH_ROUTE_NAMES = {
    MAIN: 'reach',
    MARKETPLACE: 'spa-vendor/marketplace',
    MY_PARTNERS: 'spa-vendor/contracts/list',
    PARTNER_RATES: 'spa-vendor/partner-rate/home',
    ORDERS: 'spa-vendor/orders',
    AFFILIATE_LINKS: 'spa-vendor/affiliate-links',
    GOOGLE_THINGS_TO_DO: 'spa-vendor/gttd/details',
    PROMOTE: 'promote',
};
export const SETTINGS_ROUTE_NAMES = {
    MAIN: 'settings',
    MODULES: 'modules',
    EMAIL: 'email-configuration',
    TICKET: 'ticket-configuration',
    VOUCHER: 'voucher-configuration',
    FEED: 'calendar-feed',
    INVOICELAYOUT: 'invoice-layout',
    INVOICENUMBER: 'invoice-number',
    TRANSLATIONS: 'translations',
    VAT: 'vat',
};

export const PRODUCT_UPDATES = {
    MAIN: 'Product updates',
};
export const SELL_ROUTE_NAMES = {
    MAIN: 'sell',
    WIDGETS_SETTINGS: 'widgets-settings',
    WIDGETS: 'widgets',
    NEW_WIDGETS: 'new_widgets',
    NEW_WIDGET_CONFIG: 'new_widget_config',
    CHECKOUT: 'checkout-configuration',
    EMAILCAMPAIGNS: 'email-campaigns',
    LOCALEXPERIENCES: 'local-experiences',
    PAYMENTOPTIONS: 'payment-options',
    PAYMENTPROVIDERS: 'payment-providers',
};
export const USER_ROUTES_NAMES = {
    MAIN: 'account',
    PROFILE: 'profile',
    COMPANY: 'company-data',
    USERMANAGEMENT: 'user-management',
    CONTRACT: 'contract',
    CONTRACT_CHANGE: 'contract-change',
    SIGNOUT: 'sign-out',
};
export const OVERVIEW_ROUTES_NAMES = {
    MAIN: 'overview',
};
export const SHORTCUTS_ROUTES_NAMES = {
    BOOKINGKIT: 'bookingkit',
    REACH: 'bookingkit-reach',
    APPMARKETPLACE: 'marketplace',
    BOOKING: 'booking',
    DATE: 'date',
    EXPERIENCE: 'experience',
    KNOWLEDGEBASE: 'knowledge-base',
    CONTACTSUPPORT: 'contact-support',
    ONBOARDING: 'onboarding-wizard',
    POWERSHORTCUTS: 'power-shortcuts',
};
export const ADMIN_ROUTES_NAMES = {
    MAIN: 'admin',
    PROVIDER: 'vendor',
    DASHBOARD: 'dashboard',
    CLAIMACCOUNT: 'claim-account',
    EMAIL: 'emailConfiguration',
    PAYMENT_LIST: 'paymentLists',
    OUTSTANDINGPAYMENTS: 'wirecard',
    CHARGEVOUCHERS: 'debitVoucher',
    APIPAYMENTS: 'payments',
    INVOICES: 'invoice/index',
    MANAGEAPI: 'manageApis',
    VOUCHERIMPORT: 'voucher/import',
    COUPONIMPORT: 'coupon/import',
    ORDERIMPORT: 'order/import',
    DISPUTES: 'help/disputes',
    KYCUNVERIFIFIED: 'help/KYCUnverifiedVendors',
    MARKETPARTNERS: 'marketingPartners',
    RESELLERSPAYMENTSTATUS: 'resellerPaymentStatus',
    CHANNELS: 'channels',
    PROCESSUNKNOWN: 'processUnknownPaymentRequests',
    DISABLEEVENTDATES: 'disableEventDates',
    APPLICATIONLIMITS: 'applicationlimits',
    PAYMENTREQUESTS: 'paymentRequests',
    MANAGEBKUSERS: 'manageBkUsers',
    APPLICATION: 'application',
    RATES: 'rates',
    SETUPFEE: 'setup-fee',
    SETUPFEECREATE: 'admin/setupFee-create',
    CHANGERATES: 'change rates',
    CREATEACCOUNT: 'createAccount',
    APIVENDORLIST: 'apiVendorList',
    EDITAPI: 'editApi',
    EDITAPIDETAILS: 'editApi-details',
    EDITBKUSER: 'edit-bk-user',
    PLATFORMS: 'platforms',
    INVITE: 'invite-new',
};

export const MANTICORE_IFRAME_URL = {
    EVENT_UPDATE: 'event/update',
    ON_BOARDING: '/site/Onboarding',
};

export const MANTICORE_EVENTS_URL_MAPPERS = {
    orders: new RegExp(/\/order\/index\/|\/order\/index/g, 'g'),
    experiences: new RegExp(/\/event\/update\/|\/event\/update|\/event\/index\/|\/event\/index/g, 'g'),
    'event-date': new RegExp(/\/eventDate\/view\/|\/eventDate\/view/g, 'g'),
    'event-rule': new RegExp(/\/eventRule\/update\/|\/eventRule\/update/g, 'g'),
    'month-view': new RegExp(/\/calendar\/index\/|\/calendar\/index|calendar\/index/g, 'g'),
};

export type MANTICORE_EVENTS_URL_TYPES = keyof typeof MANTICORE_EVENTS_URL_MAPPERS;

export const LEGACY_ROUTE = 'Legacy';

export const LEGACY_ROUTE_NAME = {
    EVENT_DATE: 'event-date',
    EVENT_RULE: 'event-rule',
    EVENT_RULE_CREATE: 'event-rule-create',
    EVENT_DATE_DUPLICATE: 'event-date-duplicate',
    EVENT_DUPLICATE_DETAILS: 'event-date-duplicate-details',
    EVENT_PRICING: 'event-pricing',
    SETUP_FEE_CREATE: 'admin/setupFee-create',
    PAYMENT_OPTIONS_MANGOPAY: 'payment-options/mangopay',
    REDEEM_VOUCHER: '/order/redeem-voucher',
    EVENT_SUBSCRIBE: '/event/subscribe',
    EDITAPIVENDOR: '/admin/editApi-vendor',
    ADMIN_ADD_RATE: '/admin-addRate',
    SETUP_FEE_UPDATE: '/setupFee/update',
    CREATE_BK_USER: '/admin/createBkUser',
};
