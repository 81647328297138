import { ref, computed, ComputedRef } from 'vue';
import { UserType } from '@/models/User';

const user = ref<UserType>({
    id: '', name: '', email: '', locale: 'en-XY', canSwitchSupplier: false,
});

export interface UseUserInterface {
    user: ComputedRef<UserType>,
    getUserLocale: () => ApplicationLocale,
    setUser: (userdata: UserType) =>void,
    isReady: ComputedRef<boolean>,
    setCanSwitchSupplier: (allow: boolean) => void
}

export const getUserLocale = ():ApplicationLocale => user.value.locale;

const setUser = (userdata: UserType):void => {
    user.value = { ...user.value, ...userdata };
};

const setCanSwitchSupplier = (allow: boolean) => {
    user.value.canSwitchSupplier = allow;
};

export default function useUser(): UseUserInterface {
    return {
        user: computed(() => user.value),
        setUser,
        getUserLocale,
        setCanSwitchSupplier,
        isReady: computed(() => user.value.id !== ''),
    };
}
