<template>
  <div class="ContentWrapper">
    <!-- #1 -->
    <div class="AnimationContainer">
      <div class="CubeOneContainer">
        <div class="CubeOneTop CubeOneSurface"></div>
        <div class="CubeOneRight CubeOneSurface"></div>
        <div class="CubeOneLeft CubeOneSurface"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped>
.ContentWrapper {
  height: 100vh;
}

</style>
