import { GetCurrentSupplierResponse } from '@bookingkit-private/api-v4';
import { SupplierScopes, SupplierScopeType, SupplierType } from '@/models/User';
import { SupplierServiceError } from '@/services/SupplierService/Errors';
import { SupplierResponse } from '@bookingkit-private/api-v4/models/supplier-response';

export const fromUserSupplierToSupplier = (response: GetCurrentSupplierResponse): SupplierType => {
    if (!response.user_supplier || !response.supplier_details) {
        throw SupplierServiceError.fromMalformedResponse('user_supplier|supplier_details');
    }
    const { user_supplier, supplier_details, account_status } = response;
    if (!user_supplier?.id) {
        throw SupplierServiceError.fromMalformedResponse('Supplier Id');
    }
    if (!supplier_details?.name) {
        throw SupplierServiceError.fromMalformedResponse('Supplier Name');
    }
    if (supplier_details?.avatar_url && typeof supplier_details.avatar_url !== 'string') {
        throw SupplierServiceError.fromMalformedResponse('Supplier avatar must be a string');
    }
    if (supplier_details?.account_number !== undefined && typeof supplier_details.account_number !== 'string') {
        throw SupplierServiceError.fromMalformedResponse('Supplier Account number');
    }
    if (!user_supplier?.scopes || !Array.isArray(user_supplier.scopes)) {
        throw SupplierServiceError.fromMalformedResponse('UserSupplier scopes');
    }

    const supplierScopes: (keyof typeof SupplierScopes)[] = [];
    user_supplier.scopes.forEach((scope) => {
        if (scope in SupplierScopes) {
            supplierScopes.push(scope as SupplierScopeType);
        }
    });

    return {
        id: user_supplier.id,
        name: supplier_details.name,
        logo: supplier_details.avatar_url ?? '',
        scopes: supplierScopes,
        accountNumber: supplier_details?.account_number,
        accountStatus: account_status?.status,
    };
};

export const fromApiSupplierToSupplier = (apiSupplier: SupplierResponse): SupplierType & {logo?:string} => {
    if (!apiSupplier.id) {
        throw SupplierServiceError.fromMalformedResponse('Supplier id');
    }
    if (!apiSupplier?.name) {
        throw SupplierServiceError.fromMalformedResponse('Supplier Name');
    }
    if (apiSupplier?.avatar_url && typeof apiSupplier.avatar_url !== 'string') {
        throw SupplierServiceError.fromMalformedResponse('Supplier avatar must be a string');
    }

    return {
        id: apiSupplier.id,
        accountNumber: apiSupplier.account_number,
        logo: apiSupplier.avatar_url ?? '',
        name: apiSupplier.name,
        scopes: [],
    };
};

export default {
    fromUserSupplierToSupplier,
    fromApiSupplierToSupplier,
};
