export class UnknownManticoreRouteException extends Error {
    constructor(...params: any) {
        super(...params);
        this.message = `${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnknownManticoreRouteException);
        }

        this.name = 'UnknownManticoreRouteException';
    }

    static fromUnfoundedPath(path: string): UnknownManticoreRouteException {
        return new UnknownManticoreRouteException(`Could not find any route matching path:  \`${path}\``);
    }
}

export default UnknownManticoreRouteException;
